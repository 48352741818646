import * as ko from "knockout";
import * as _ from "lodash";

import {
    CommentWidgetSeed,
    DocumentWidgetSeed,
    IdNameProperty,
    UsersService,
} from "../backend/v1";
import { NewComment } from "../knockout/components/commentWidget";
import {
    PreselectLocationItem,
    LocationItem,
} from "../knockout/components/locationPicker/locationPicker";
import { dialogStarter } from "../knockout/dialogStarter";
import { FetchExtended } from "../knockout/extensions/fetch";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import { CheckExtended } from "../knockout/extensions/invalid";
import { assert } from "../lib/assert";
import {
    formatDate,
    parseDate,
} from "../lib/flatpickr";
import {
    getTranslation,
    formatDecimal,
    parseDecimal,
    TranslationTemplates,
} from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { session } from "../lib/pyratSession";
import {
    mainMenu,
    notifications,
} from "../lib/pyratTop";
import {
    addWorkDays,
    AjaxResponse,
    cgiScript,
    checkDecimal,
    getFormData,
    getUrl,
    isInvalidCalendarDate,
} from "../lib/utils";

import template from "./orderRequestDetails.html";

import "./orderRequestDetails.scss";
import "/scss/request_status_bar.scss";


interface Params {
    orderRequestId: number | null;
    loadCallback?: () => void;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

interface Seed {
    order_request_details?:  {
        id: number;
        origin_id: number;
        delivery_date: string;
        priority: string;
        owner_id: number;
        responsible_basic_id: number;
        responsible_basic_fullname: string;
        default_responsible_basic_fullname?: string;
        responsible_staff_id: number;
        project_id: number;
        species_id: number;
        licence_id: number;
        classification_id: number;
        budget_name: string;
        location_string: string;
        rack_id: number;
        room_id: number;
        area_id: number;
        building_id: number;
        cage_type: string;
        cage_category_id: number;
        animals_per_cage: number;
        diet: string;
        strain_id: number;
        genetic_bg_id: number;
        use_catalog: boolean;
        quantity_male: number;
        quantity_female: number;
        quantity_unknown: number;
        pregnant: boolean;
        embryonic_stage: number;
        plug_date: string;
        with_litter: boolean;
        litter_age: number;
        age: string;
        age_max: string;
        age_use_range: boolean;
        weight: string;
        weight_max: string;
        weight_use_range: boolean;
        phenotypic_chart: boolean;
        health_status: boolean;
        history: {
            request_id: number;
            event_date: string;
            userfullname: string;
            description: string;
        }[];
        catalog_order_items: {
            id: number;
            catalog_item_id: number;
            catalog_item_label: string;
            import_date: string;
            price: string;
            product_type_label: string;
            quantity: number;
            show_import_button: boolean;
       }[];
    };
    fields: Fields;
    buttons: string[];
    status_bar_items: {
        name: string;
        label: string;
        is_current: boolean;
        is_clickable: boolean;
        confirm_message: string;
    }[];
    origins: {
        id: number;
        name: string;
        type: string;
        delivery_days_js: number[];
        delivery_days_labels: string[];
        responsible_id: number;
        responsible_fullname: string;
    }[];
    priorities: {
        id: string;
        label: string;
    }[];
    owners: {
        userid: number;
        fullname: string;
        price_category_id: number;
    }[];
    responsibles_staff: {
        userid: number;
        fullname: string;
    }[];
    species: {
        id: number;
        name: string;
        weight_unit: string;
        percage: number;
        gestation_days: number;
        wean_days: number;
        calculation_base: undefined | "age" | "strain" | "weight";
    }[];
    licenses: {
        id: number;
        name: string;
        budget_name: string;
    }[];
    classifications: {
        id: number;
        name: string;
        license_id: number;
        species_id: number;
        permitted_strain_ids: number[];
    }[];
    strains: Strain[];
    projects: {
        id: number;
        name: string;
    }[];
    genetic_backgrounds: {
        id: number;
        name: string;
    }[];
    cage_types: {
        cagetype: string;
        cagetype_i18n: string;
    }[];
    cage_categories: {
        id: number;
        name: string;
    }[];
    strain_price_list: {
        species_id: number;
        strain_id: number;
        price_category_id: number;
        price: number;
    }[];
    catalogs: Catalog[];
    product_types: {
        id: "animal" | "additional";
        name: string;
    }[];
    catalog_items: CatalogItem[];
    catalog_items_price_list: {
        id: number;
        price: string;
        price_category_id: number;
        catalog_item_id: number;
    }[];
    comment_widget_seed?: CommentWidgetSeed;
    document_widget_seed?: DocumentWidgetSeed;
}

interface FieldsDefinition {
    editable: boolean;
    mandatory: boolean;
}

interface Fields {
    origin: FieldsDefinition;
    delivery_date: FieldsDefinition;
    priority: FieldsDefinition;
    owner: FieldsDefinition;
    responsible_basic: FieldsDefinition;
    responsible_staff: FieldsDefinition;
    species: FieldsDefinition;
    strain: FieldsDefinition;
    project: FieldsDefinition;
    genetic_background: FieldsDefinition;
    licence: FieldsDefinition;
    classification: FieldsDefinition;
    quantity_males: FieldsDefinition;
    quantity_females: FieldsDefinition;
    quantity_unknown: FieldsDefinition;
    pregnant: FieldsDefinition;
    embryonic_stage: FieldsDefinition;
    plug_date: FieldsDefinition;
    with_litter: FieldsDefinition;
    litter_age: FieldsDefinition;
    animals_per_cage: FieldsDefinition;
    cage_type: FieldsDefinition;
    cage_category: FieldsDefinition;
    age: FieldsDefinition;
    weight: FieldsDefinition;
    diet: FieldsDefinition;
    location: FieldsDefinition;
    comment: FieldsDefinition;
    catalog: FieldsDefinition;
    product_type: FieldsDefinition;
    catalog_item: FieldsDefinition;
    quantity_item: FieldsDefinition;
    phenotype_checkbox: FieldsDefinition;
    health_checkbox: FieldsDefinition;
}

interface Catalog {
    id: number;
    name: string;
    origin_id: number;
}

interface CatalogItem {
    id: number;
    catalog_id: number;
    product_type: "animal" | "additional";
    species_id: number;
    strain_id: number;
    catalog_item_label: string;
}

interface CatalogOrderItem {
    id?: number;
    product_type_label: string;
    catalog_item_id: number;
    catalog_item_label: string;
    price: string;
    import_date: string;
    quantity: number;
    show_import_button: boolean;
}

interface Strain {
    id: number;
    name_with_id: string;
    official_name: string;
    species_id: number;
}

interface SubmitData {
    [key: string]: string | number;
    request_id: number | null;
    origin_id: number;
    delivery_date: string;
    priority: string;
    owner_id: number;
    responsible_basic_id: number;
    responsible_staff_id: number;
    species_id: number;
    strain_id: number;
    project_id: number;
    genetic_bg_id: number;
    licence_id: number;
    classification_id: number;
    licence_overuse_limit: 1 | undefined;
    use_catalog: 1 | undefined;
    quantity_male: number;
    quantity_female: number;
    quantity_unknown: number;
    pregnant: 1 | undefined;
    embryonic_stage: number;
    plug_date: string;
    with_litter: 1 | undefined;
    litter_age: number;
    animals_per_cage: number;
    cage_type: string;
    cage_category_id: number;
    age: string;
    age_max: string;
    age_use_range: 1 | undefined;
    weight: string;
    weight_max: string;
    weight_use_range: 1 | undefined;
    diet: string;
    building_id: number;
    area_id: number;
    room_id: number;
    rack_id: number;
    phenotypic_chart: 1 | undefined;
    health_status: 1 | undefined;
    new_request?: "1";
    comment?: string;
    repeated_delivery_dates?: string;
    catalog_order_items?: string;
    submitted?: "1";
}


class OrderRequestDetailsViewModel {

    private readonly params;
    private readonly dialog;

    public readonly seed: FetchExtended<ko.Observable<AjaxResponse<Seed>>>;

    private readonly isNew: ko.PureComputed<boolean>;
    private readonly isInternalOrder: ko.PureComputed<boolean>;
    private readonly isExternalOrder: ko.PureComputed<boolean>;
    private readonly originId: CheckExtended<ko.Observable<number>>;
    private readonly deliveryDate: CheckExtended<ko.Observable<string>>;
    private readonly repeatedDeliveryDatesVisible: ko.Observable<boolean>;
    private readonly repetitionDates: CheckExtended<ko.ObservableArray<string>>;
    private readonly priority: CheckExtended<ko.Observable<string>>;
    private readonly ownerId: CheckExtended<ko.Observable<number>>;
    private readonly availableResponsiblesBasic: FetchBackendExtended<ko.Observable<{
        userid: number;
        fullname: string;
    }[]>>;
    private readonly responsibleBasicId: CheckExtended<ko.Observable<number>>;
    private readonly defaultResponsibleBasicFullname: ko.Observable<string>;
    private readonly responsibleStaffId: CheckExtended<ko.Observable<number>>;
    private readonly defaultResponsibleStaffFullname: ko.PureComputed<string>;
    private readonly projectId: CheckExtended<ko.Observable<number>>;
    private readonly speciesId: CheckExtended<ko.Observable<number>>;
    private readonly speciesIdIsUserSelected: ko.Observable<boolean> = ko.observable(false);
    private readonly availableClassifications: ko.PureComputed<IdNameProperty[]>;
    private readonly availableLicenses: ko.PureComputed<IdNameProperty[]>;
    private readonly licenseDenom: string;
    private readonly licenseId: CheckExtended<ko.Observable<number>>;
    private readonly licenseIdIsUserSelected: ko.Observable<boolean> = ko.observable(false);
    private readonly classificationId: CheckExtended<ko.Observable<number>>;
    private readonly showLicenceOveruseLimit: ko.Observable<boolean>;
    private readonly licenceOveruseLimit: ko.Observable<boolean>;
    private readonly budgetName: ko.Observable<string>;
    private readonly locationSelectType: ko.Observable<string[]>;
    private readonly selectedLocation: CheckExtended<ko.Observable<LocationItem>>;
    private readonly preselectLocation: ko.Observable<PreselectLocationItem>;
    private readonly locationInitialized: ko.Observable<boolean | "loading">;
    private readonly areaId: ko.Observable<number>;
    private readonly buildingId: ko.Observable<number>;
    private readonly roomId: ko.Observable<number>;
    private readonly rackId: ko.Observable<number>;
    private readonly locationString: ko.Observable<string>;
    private readonly cageType: ko.Observable<string>;
    private readonly cageCategoryId: ko.Observable<number>;
    private readonly animalsPerCage: ko.Observable<number>;
    private readonly diet: ko.Observable<string>;
    private readonly comment: CheckExtended<ko.Observable<NewComment>>;
    private readonly commentWidgetSeed: ko.Observable<CommentWidgetSeed>;

    private readonly useCatalog: ko.Observable<boolean>;
    private readonly availableStrains: ko.PureComputed<Strain[]>;
    private readonly availableStrainsCustom: ko.PureComputed<Strain[]>;
    private readonly availableStrainsCatalog: ko.PureComputed<Strain[]>;
    private readonly strainId: CheckExtended<ko.Observable<number>>;
    private readonly geneticBackgroundId: CheckExtended<ko.Observable<number>>;
    private readonly quantityMales: CheckExtended<ko.Observable<number>>;
    private readonly quantityFemales: CheckExtended<ko.Observable<number>>;
    private readonly quantityUnknown: CheckExtended<ko.Observable<number>>;
    private readonly quantityFilled: CheckExtended<ko.PureComputed<boolean>>;
    private readonly pregnant: ko.Observable<boolean>;
    private readonly embryonicStage: CheckExtended<ko.Observable<number>>;
    private readonly plugDate: CheckExtended<ko.Observable<string>>;
    private readonly embryonicStageOrPlugDateFilled: CheckExtended<ko.PureComputed<boolean>>;
    private readonly withLitter: ko.Observable<boolean>;
    private readonly litterAge: CheckExtended<ko.Observable<number>>;
    private readonly age: CheckExtended<ko.Observable<string>>;
    private readonly ageRangeSelected: ko.Observable<boolean>;
    private readonly ageMax: CheckExtended<ko.Observable<string>>;
    private readonly weight: CheckExtended<ko.Observable<string>>;
    private readonly weightRangeSelected: ko.Observable<boolean>;
    private readonly weightMax: CheckExtended<ko.Observable<string>>;
    private readonly ageRange: CheckExtended<ko.PureComputed<string[]>>;
    private readonly weightRange: CheckExtended<ko.PureComputed<string[]>>;
    private readonly ageOrWeightFilled: CheckExtended<ko.PureComputed<boolean>>;

    private readonly showAddCatalogOrderItem: ko.Observable<boolean>;
    private readonly availableCatalogs: ko.PureComputed<Catalog[]>;
    private readonly catalogId: CheckExtended<ko.Observable<number>>;
    private readonly productType: ko.Observable<"animal" | "additional">;
    private readonly availableCatalogItems: ko.PureComputed<CatalogItem[]>;
    private readonly catalogItemId: CheckExtended<ko.Observable<number>>;
    private readonly catalogPrice: CheckExtended<ko.PureComputed<string>>;
    private readonly quantityItems: CheckExtended<ko.Observable<number>>;
    private readonly catalogOrderItems: ko.ObservableArray<CatalogOrderItem>;
    private readonly catalogTotalPrice: ko.PureComputed<string>;

    private readonly phenotypicChartPrepared: ko.Observable<boolean>;
    private readonly healthStatusVerified: ko.Observable<boolean>;
    private readonly history: ko.ObservableArray;

    public  readonly submitInProgress: ko.Observable<boolean>;
    private readonly selectedTab: ko.Observable<"comments" | "history" | "documents">;
    private readonly errors: ko.ObservableArray<string>;
    private readonly errorMessages: ko.PureComputed<string[]>;
    private readonly reloadRequired: ko.Observable<boolean>;
    private readonly canSubmit: ko.PureComputed<boolean>;

    constructor(params: Params, dialog: KnockoutPopup) {
        this.params = params;
        this.dialog = dialog;

        if (params.orderRequestId) {
            dialog.setTitle(getTranslation("Order request") + " #" + params.orderRequestId);
        } else {
            dialog.setTitle(getTranslation("New order request"));
        }

        dialog.addOnClose(() => {
            if (typeof params.closeCallback === "function") {
                params.closeCallback();
            }

            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        /* initial data */
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                const request: { [key: string]: any } = {
                    "get_order_request_details": "1",
                };
                if (this.params.orderRequestId) {
                    request["order_request_id"] = this.params.orderRequestId;
                }
                const form = getFormData({ request: JSON.stringify(request) });
                return fetch(cgiScript("order_request_detail.py"), { method: "POST", body: form, signal });
            },
        });
        this.seed.subscribeOnce(() => {
            if (typeof params.loadCallback === "function") {
                params.loadCallback();
            }
        });

        /* content */
        this.isNew = ko.pureComputed(() => { return !params.orderRequestId;});

        this.originId = ko.observable().extend({
            invalid: (v) => {
                return !!(this.seed().fields.origin.mandatory && !v);
            },
        });

        this.isInternalOrder = ko.computed(() => {
            if (this.originId()) {
                const origin = _.find(this.seed().origins, { id: this.originId() });
                return (origin.type === "internal");
            }
        });

        this.isExternalOrder = ko.computed(() => {
            if (this.originId()) {
                const origin = _.find(this.seed().origins, { id: this.originId() });
                return (origin.type === "external");
            }
        });

        this.deliveryDate = ko.observable().extend({
            invalid: (v) => {
                if (this.repeatedDeliveryDatesVisible() && !v) {
                    return true;
                }
                if (this.seed().fields.delivery_date.mandatory && !v) {
                    return true;
                }
                if (isInvalidCalendarDate(v)) {
                    return getTranslation("Invalid date");
                }

                // the initial date is accepted even if it was a wrong weekday for the origin
                return v !== this.seed().order_request_details.delivery_date ? this.isWrongWeekday(v) : false;
            },
        });

        this.repeatedDeliveryDatesVisible = ko.observable(false);
        this.repetitionDates = ko.observableArray().extend({
            invalid: (v) => {
                let invalidDate;

                if (this.repeatedDeliveryDatesVisible()) {
                    if (!v.length) {
                        return true;
                    }

                    invalidDate = _.find(v, function (deliveryDate) {
                        return deliveryDate.isInvalid();
                    });

                    if (invalidDate) {
                        return invalidDate.errorMessage();
                    }
                }

                return false;
            },
        });

        this.priority = ko.observable().extend({
            invalid: (v) => {
                return !!(this.seed().fields.priority.mandatory && !v);
            },
        });

        this.ownerId = ko.observable().extend({
            invalid: (v) => {
                return !!(this.seed().fields.owner.mandatory && !v);
            },
        });

        // load responsibles_basic according to the selected owner
        this.availableResponsiblesBasic = ko.observable().extend({
            fetchBackend: () => {
                if (this.seed() && this.seed().fields.responsible_basic.editable && this.ownerId()) {
                    const promise = UsersService.getResponsibles({ userId: [this.ownerId()] });
                    promise.then((data) => {
                        const current = {
                            username: "",
                            userid: this.seed().order_request_details.responsible_basic_id,
                            fullname: this.seed().order_request_details.responsible_basic_fullname,
                        };
                        if (current.userid && !_.find(data, { userid: current.userid })) {
                            data.push(current);
                        }
                        return data || [];
                    });
                    return promise;
                }
            },
        });

        this.responsibleBasicId = ko.observable().extend({
            invalid: (value) => !!(this.seed().fields.responsible_basic.mandatory && !value),
        });

        this.defaultResponsibleBasicFullname = ko.observable();

        this.responsibleStaffId = ko.observable().extend({
            invalid: (v) => {
                return !!(this.seed().fields.responsible_staff.mandatory && !v);
            },
        });

        this.originId.subscribe((v) => {

            // suggest responsible_staff according to the selected origin
            if (this.isNew() && session.pyratConf.ORDER_REQUEST_RESPONSIBLE_ASSIGN === "by_class" && v) {
                const origin = _.find(this.seed().origins, { id: v });
                this.responsibleStaffId(origin.responsible_id);
            }

            // reset catalog order for internal orders
            if (this.isNew() && this.isInternalOrder()) {
                this.useCatalog(false);
            }

            // select catalog order for external orders
            if (this.isNew() && this.isExternalOrder() && session.pyratConf.ORDER_REQUEST_CATALOG_BY_DEFAULT) {
                this.useCatalog(true);
            }

            // reset potential error messages
            this.errors([]);
        });

        this.defaultResponsibleStaffFullname = ko.pureComputed(() => {
            if (session.pyratConf.ORDER_REQUEST_RESPONSIBLE_ASSIGN === "by_class" && this.originId()) {
                const origin = _.find(this.seed().origins, { id: this.originId() });
                return (origin.responsible_fullname);
            }
        });

        this.projectId = ko.observable().extend({
            invalid: (v) => {
                return !!(this.seed().fields.project.mandatory && !v);
            },
        });

        this.speciesId = ko.observable().extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    // the species is not mandatory for catalog order
                    return false;
                }
                return !!(this.seed().fields.species.mandatory && !v);
            },
        });

        this.availableLicenses = ko.pureComputed(() => {
            let result: IdNameProperty[] = [];
            // get licenses which have a classification for the selected species
            if (this.speciesId()) {
                const classificationsForSpecies = _.filter(this.seed().classifications, { species_id: this.speciesId() });
                _.each(this.seed().licenses, (license) => {
                    if (_.find(classificationsForSpecies, { license_id: license.id }) || license.id === -1) {
                        if (!_.find(result, { id: license.id })) {
                            result.push({
                                id: license.id,
                                name: license.name + (license.budget_name ? " (" + license.budget_name + ")" : ""),
                            });
                        }
                    }
                });
                // remove the delimiter if that is the last item in the list
                if (result.length > 0 && _.last(result).id === -1) {
                    result.pop();
                }
            } else {
                result = [];
            }
            // include the current value .. as long as no fields changed
            // it might have got lost by the above filtering
            if (this.params.orderRequestId && !this.speciesIdIsUserSelected()) {
                const detailData = this.seed().order_request_details;
                const current = _.find(this.seed().licenses, { id: detailData.licence_id });
                if (current && current.id && !_.find(result, { id: current.id })) {
                    result.push(current);
                }
            }

            return result;
        });

        this.availableClassifications = ko.pureComputed(() => {
            let result: IdNameProperty[];
            // get classifications for the selected license and species
            if (this.licenseId() && this.speciesId()) {
                result = _.filter(this.seed().classifications, (classification) => {
                    return (classification.license_id === this.licenseId() &&
                            classification.species_id === this.speciesId()) || classification.id === -1;
                });
                // remove the delimiter if that is the last item in the list
                if (result.length > 0 && _.last(result).id === -1) {
                    result.pop();
                }
            } else {
                result = [];
            }
            // include the current value .. as long as no fields changed
            // it might have got lost by the above filtering
            if (this.params.orderRequestId && !this.licenseIdIsUserSelected() && !this.speciesIdIsUserSelected()) {
                const detailData = this.seed().order_request_details;
                const current = _.find(this.seed().classifications, { id: detailData.classification_id });
                if (current && current.id && !_.find(result, { id: current.id })) {
                    result.push(current);
                }
            }

            return result;
        });

        this.licenseDenom = TranslationTemplates.license({ cap: true, trans: true });

        this.licenseId = ko.observable().extend({
            invalid: (v) => {
                if (this.seed().fields.licence.mandatory && !v) return true;
                // licence becomes mandatory as soon as classification is selected
                return !!(this.classificationId() && !v);
            },
        });

        this.classificationId = ko.observable().extend({
            invalid: (v) => {
                if (this.seed().fields.classification.mandatory && !v) return true;
                // classification becomes mandatory as soon as licence is selected
                return !!(this.licenseId() && !v);
            },
        });

        this.showLicenceOveruseLimit = ko.observable();
        this.licenceOveruseLimit = ko.observable();

        this.budgetName = ko.observable();

        this.locationSelectType = ko.observable(session.pyratConf.ORDER_LOCATION_LEVEL);
        this.selectedLocation = ko.observable().extend({
            invalid: (v) => {
                return !!(this.seed().fields.location.mandatory && !v && this.locationInitialized() !== "loading");
            },
        });
        this.preselectLocation = ko.observable();
        this.locationInitialized = ko.observable();
        this.areaId = ko.observable();
        this.buildingId = ko.observable();
        this.roomId = ko.observable();
        this.rackId = ko.observable();

        this.selectedLocation.subscribe((v) => {
            this.buildingId(v && v.type === "building" ? v.building_id : undefined);
            this.areaId(v && v.type === "area" ? v.area_id : undefined);
            this.roomId(v && v.type === "room" ? v.room_id : undefined);
            this.rackId(v && v.type === "rack" ? v.rack_id : undefined);
        });
        this.locationString = ko.observable();

        this.cageType = ko.observable("Stock").extend({
            invalid: (v) => {
                return !!(this.seed().fields.cage_type.mandatory && !v);
            },
        });

        this.cageCategoryId = ko.observable();

        this.animalsPerCage = ko.observable().extend({
            invalid: (v) => {
                if (this.seed().fields.animals_per_cage.mandatory && !v) return true;
                return !(_.isNumber(v) && String(v).match(/^\d+$/) && v > 0) && getTranslation("Value must be greater than 0");
            },
        });

        // suggest animalsPerCage according to the species
        this.speciesId.subscribe((v) => {
            if (this.isNew()) {
                if (v) {
                    const species = _.find(this.seed().species, { id: v });
                    assert("percage" in species, "Missing key: percage");
                    this.animalsPerCage(species.percage);
                } else {
                    this.animalsPerCage(session.pyratConf.PERCAGE);
                }
            }
        });

        this.diet = ko.observable().extend({
            invalid: (v) => {
                return !!(this.seed().fields.diet.mandatory && !v);
            },
        });

        // comment field on new request
        this.comment = ko.observable().extend({
            invalid: (v) => {
                return !!(this.seed().fields.comment.mandatory && !(v && v.comment));
            },
        });

        this.commentWidgetSeed = ko.observable();

        this.useCatalog = ko.observable(false);

        this.availableStrains = ko.pureComputed(() => {
            let result = [];
            let strainsWithSpecies;
            let strainsNoSpecies;

            // EXTENDED_STRAIN_FIELDS: load strains according to the selected species
            // strains with the selected species + separator + strains without species
            if (session.pyratConf.EXTENDED_STRAIN_FIELDS) {
                strainsWithSpecies = ko.utils.arrayFilter(this.seed().strains, (strain) => {
                    return this.speciesId() && strain.species_id === this.speciesId();
                });

                strainsNoSpecies = ko.utils.arrayFilter(this.seed().strains, function(strain) {
                    return !strain.species_id;
                });

                result = strainsWithSpecies;
                if (strainsNoSpecies.length !== 0) {
                    result.push({ id: 0, name_with_id: "separator", official_name: undefined, species_id: undefined });
                    // eslint-disable-next-line prefer-spread
                    result.push.apply(result, strainsNoSpecies);
                }
            } else {
                result = this.seed().strains;
            }

            return result;
        });

        this.availableStrainsCustom = ko.pureComputed(() => {
            let result = this.availableStrains();
            let price;

            // INTERNAL_ORDER_BILLING: allow only strains that have a price
            if (session.pyratConf.INTERNAL_ORDER_BILLING) {
                if (this.originId() && this.speciesId() && this.ownerId()) {
                    const origin = _.find(this.seed().origins, { id: this.originId() });
                    const species = _.find(this.seed().species, { id: this.speciesId() });
                    const owner = _.find(this.seed().owners, { userid: this.ownerId() });

                    if (origin.type === "internal" && species.calculation_base === "strain") {
                        result = ko.utils.arrayFilter(result, (strain) => {

                            // find price for the strain
                            price = ko.utils.arrayFirst(this.seed().strain_price_list, (price) => {
                                return (price.strain_id === strain.id &&
                                        price.species_id === this.speciesId() &&
                                        price.price_category_id === owner.price_category_id);
                            });

                            return !!(price || strain.name_with_id === "separator");
                        });
                    }
                }
            }

            // always include the current value
            // it might have got lost by the above filtering
            const current = _.find(this.seed().strains, { id: this.seed().order_request_details.strain_id });

            if (current && current.id && !_.find(result, { id: current.id })) {
                result.push(current);
            }

            return result;
        });

        this.availableStrainsCatalog = ko.pureComputed(() => {
            let result = this.availableStrains();

            if (this.productType() === "animal") {

                // get strains which are available for the selected classification
                if (this.classificationId()) {
                    const classification = _.find(this.seed().classifications, { id: this.classificationId() });
                    assert("permitted_strain_ids" in classification, "Missing property 'permitted_strain_ids'");
                    // empty list in permitted_strains means the classification is allowed for all strains
                    if (classification.permitted_strain_ids.length > 0) {
                        result = _.filter(result, (strain) => {
                            return classification.permitted_strain_ids.includes(strain.id);
                        });
                    }
                }
                // get strains which are available for the selected catalog
                if (this.catalogId()) {
                    const catalogItems = _.filter(this.seed().catalog_items, {
                        catalog_id: this.catalogId(),
                        product_type: this.productType(),
                    });
                    result = _.filter(result, (strain) => {
                        return Boolean(_.find(catalogItems, { strain_id: strain.id }));
                    });
                }
            } else {
                result = [];
            }

            return result;
        });

        this.strainId = ko.observable().extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    // the strain is not mandatory for catalog order
                    return false;
                }

                if (this.seed().fields.strain.mandatory && !v) return true;

                // INTERNAL_ORDER_BILLING: strain is mandatory when a species is selected with prices based on strain
                if (session.pyratConf.INTERNAL_ORDER_BILLING && this.isInternalOrder()) {
                    if (this.speciesId()) {
                        const species = _.find(this.seed().species, { id: this.speciesId() });
                        assert("calculation_base" in species, "Missing property 'calculation_base'");
                        if (species.calculation_base === "strain" && !v) return true;
                    }
                }
                return false;
            },
        });

        this.geneticBackgroundId = ko.observable().extend({
            invalid: (v) => {
                return !!(this.seed().fields.genetic_background.mandatory && !v);
            },
        });

        this.quantityMales = ko.observable().extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    // field is not mandatory for catalog order
                    return false;
                }
                if (this.seed().fields.quantity_males.mandatory && !v) return true;
                if (!((_.isNumber(v) && String(v).match(/^\d+$/) && v >= 0) || _.isUndefined(v))) {
                    return getTranslation("Invalid number");
                }
                return (this.quantityFilled.isInvalid());
            },
        });

        this.quantityFemales = ko.observable().extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    // field is not mandatory for catalog order
                    return false;
                }
                if (this.seed().fields.quantity_females.mandatory && !v) return true;
                if (!((_.isNumber(v) && String(v).match(/^\d+$/) && v >= 0) || _.isUndefined(v))) {
                    return getTranslation("Invalid number");
                }
                if ((this.withLitter() || this.pregnant()) && !(v > 0)) {
                    return getTranslation("The number of females must be greater than 0");
                }
                return (this.quantityFilled.isInvalid());
            },
        });

        this.quantityUnknown = ko.observable().extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    // field is not mandatory for catalog order
                    return false;
                }
                if (this.seed().fields.quantity_unknown.mandatory && !v) return true;
                if (!((_.isNumber(v) && String(v).match(/^\d+$/) && v >= 0) || _.isUndefined(v))) {
                    return getTranslation("Invalid number");
                }
                return (this.quantityFilled.isInvalid());
            },
        });

        // calculated fields to check "at least one" is filled
        this.quantityFilled = ko.pureComputed(() => {
            return this.quantityMales() > 0 ||
                   this.quantityFemales() > 0 ||
                   this.quantityUnknown() > 0;
        }).extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    // field is not mandatory for catalog order
                    return false;
                }
                return !v;
            },
        });

        this.pregnant = ko.observable();

        this.embryonicStage = ko.observable().extend({
            invalid: (v) => {
                // validate this field only if 'pregnant' is activated
                if (this.pregnant()) {
                    if (this.seed().fields.embryonic_stage.mandatory && !v) return true;
                    if (this.embryonicStageOrPlugDateFilled.isInvalid()) return true;

                    if (!_.isUndefined(v) && this.speciesId()) {
                        const species = _.find(this.seed().species, { id: this.speciesId() });
                        assert("gestation_days" in species, "Missing property 'gestation_days'");
                        return !(_.isNumber(v) && String(v).match(/^\d+$/) && v >= 0 && v <= species.gestation_days) &&
                            getTranslation("Please enter a number between 0 and %s").replace("%s",
                                species.gestation_days.toString());
                    }
                }
                return false;
            },
        });
        this.embryonicStage.subscribe((v) => {
            // reset the plug date
            if (v) {
                this.plugDate(undefined);
            }
        });

        this.plugDate = ko.observable().extend({
            invalid: (v) => {
                // validate this field only if 'pregnant' is activated
                if (this.pregnant()) {
                    if (this.seed().fields.plug_date.mandatory && !v) return true;
                    if (this.embryonicStageOrPlugDateFilled.isInvalid()) return true;

                    if (v && isInvalidCalendarDate(v)) {
                        return getTranslation("Invalid date");
                    }
                }
                return false;
            },
        });
        this.plugDate.subscribe((v) => {
            // reset the embryonic stage
            if (v) {
                this.embryonicStage(undefined);
            }
        });

        // calculated field to check that either plug date or embryonic stage is filled - not both
        this.embryonicStageOrPlugDateFilled = ko.pureComputed(() => {
            return !!(_.isUndefined(this.embryonicStage()) && this.plugDate()) ||
                     (!_.isUndefined(this.embryonicStage()) && !this.plugDate()) ;
        }).extend({
            invalid: (v) => {
                if (this.pregnant()) {
                    return !v;
                }
                return false;
            },
        });

        this.withLitter = ko.observable();

        this.litterAge = ko.observable().extend({
            invalid: (v) => {
                // validate this field only if 'withLitter' is activated
                if (this.withLitter()) {
                    if (this.seed().fields.litter_age.mandatory && !v) return true;
                    if (this.speciesId()) {
                        const species = _.find(this.seed().species, { id: this.speciesId() });
                        assert("wean_days" in species, "Missing property 'wean_days'");
                        return !(_.isNumber(v) && String(v).match(/^\d+$/) && v >= 0 && v <= species.wean_days) &&
                            getTranslation("Please enter a number between 0 and %s").replace("%s",
                                species.wean_days.toString());
                    }
                }
                return false;
            },
        });

        this.age = ko.observable().extend({
            invalid: (v) => {
                if (this.seed().fields.age.mandatory && !v) return true;

                // INTERNAL_ORDER_BILLING: age is mandatory when a species is selected with prices based on age
                if (session.pyratConf.INTERNAL_ORDER_BILLING && this.isInternalOrder()) {
                    if (this.speciesId()) {
                        const species = _.find(this.seed().species, { id: this.speciesId() });
                        assert("calculation_base" in species, "Missing property 'calculation_base'");
                        if (species.calculation_base === "age" && !v) return true;
                    }
                }
                if (v) { return checkDecimal(v, session.localesConf.decimalSymbol, 5, 3); }
                return this.ageOrWeightFilled.isInvalid();
            },
        });

        this.ageRangeSelected = ko.observable();

        this.ageMax = ko.observable().extend({
            invalid: (v) => {
                if (v) { return checkDecimal(v, session.localesConf.decimalSymbol, 5, 3); }
                return this.ageOrWeightFilled.isInvalid();
            },
        });

        this.weight = ko.observable().extend({
            invalid: (v) => {
                if (this.seed().fields.weight.mandatory && !v) return true;

                // INTERNAL_ORDER_BILLING: weight is mandatory when a species is selected with prices based on weight
                if (session.pyratConf.INTERNAL_ORDER_BILLING && this.isInternalOrder()) {
                    if (this.speciesId()) {
                        const species = _.find(this.seed().species, { id: this.speciesId() });
                        assert("calculation_base" in species, "Missing property 'calculation_base'");
                        if (species.calculation_base === "weight" && !v) return true;
                    }
                }
                if (v) { return checkDecimal(v, session.localesConf.decimalSymbol, 6, 4); }
                return this.ageOrWeightFilled.isInvalid();
            },
        });

        this.weightRangeSelected = ko.observable();

        this.weightMax = ko.observable().extend({
            invalid: (v) => {
                if (v) { return checkDecimal(v, session.localesConf.decimalSymbol, 6, 4); }
                return this.ageOrWeightFilled.isInvalid();
            },
        });

        // calculated field to check valid age range
        this.ageRange = ko.pureComputed(() => {
            return this.ageRangeSelected() ? [this.age(), this.ageMax()] : null;
        }).extend({
            invalid: (v) => {
                if (v) {
                    return (parseFloat(this.age()) > parseFloat(this.ageMax())) && getTranslation("Invalid age range");
                }
                return false;
            },
        });

        // calculated field to check valid weight range
        this.weightRange = ko.pureComputed(() => {
            return this.weightRangeSelected() ? [this.weight(), this.weightMax()] : null;
        }).extend({
            invalid: (v) => {
                if (v) {
                    return (parseFloat(this.weight()) > parseFloat(this.weightMax())) && getTranslation("Invalid weight range");
                }
                return false;
            },
        });

        // calculated fields to check mandatory fields with "at least one" is filled
        this.ageOrWeightFilled = ko.pureComputed(() => {
            return parseInt(this.age(), 10)  > 0 ||
                   parseInt(this.weight(), 10)  > 0 ||
                   (this.ageRangeSelected() && parseInt(this.ageMax(), 10)  > 0) ||
                   (this.weightRangeSelected() && parseInt(this.weightMax(), 10)  > 0);
        }).extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    // field is not mandatory for catalog order
                    return false;
                }
                if (this.pregnant() || this.withLitter()) {
                    // if 'pregnant' or 'with litter'
                    // then age and weight is not necessary
                    return false;
                }
                return !v;
            },
        });

        // catalog items list

        this.showAddCatalogOrderItem = ko.observable(false);

        this.availableCatalogs = ko.pureComputed(() => {
            let result: Catalog[] = [];
            // get catalogs for the selected origin
            if (this.useCatalog() && this.originId()) {
                result = _.filter(this.seed().catalogs, { "origin_id": this.originId() });
            }
            return result;
        });

        this.catalogId = ko.observable().extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    return !v;
                }
                return false;
            },
        });
        this.productType = ko.observable();

        this.availableCatalogItems = ko.pureComputed(() => {
            let result: CatalogItem[] = [];

            // get catalog items for the selected catalog and product type
            if (this.useCatalog() && this.catalogId()) {
                if (this.productType() === "additional") {
                    result = _.filter(this.seed().catalog_items, { "catalog_id": this.catalogId(),
                        "product_type": this.productType() });

                } else if (this.productType() === "animal" && this.speciesId() && this.strainId()) {
                    result = _.filter(this.seed().catalog_items, { "catalog_id": this.catalogId(),
                        "product_type": this.productType(),
                        "species_id": this.speciesId(),
                        "strain_id": this.strainId() });
                }
            }
            return result;
        });

        this.catalogItemId = ko.observable().extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    return !v;
                }
                return false;
            },
        });
        this.catalogPrice = ko.pureComputed(() => {
            if (this.catalogItemId() && this.ownerId()) {
                const owner = _.find(this.seed().owners, { userid: this.ownerId() });

                const priceData = _.filter(this.seed().catalog_items_price_list, {
                    catalog_item_id: this.catalogItemId(),
                    price_category_id: owner.price_category_id,
                });
                if (priceData.length === 1) {
                    return priceData[0].price;
                }
            }
        }).extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    return !v;
                }
                return false;
            },
        });
        this.quantityItems = ko.observable().extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    if (!((_.isNumber(v) && String(v).match(/^\d+$/) && v >= 0) || _.isUndefined(v))) {
                        return getTranslation("Invalid number");
                    }
                    if (v <= 0) {
                        return getTranslation("The number of items must be greater than 0");
                    }
                    return !v;
                }
                return false;
            },
        });
        this.catalogOrderItems = ko.observableArray().extend({
            invalid: (v) => {
                if (this.useCatalog()) {
                    return v.length <= 0;
                }
                return false;
            },
        });

        this.catalogTotalPrice = ko.pureComputed(() => {
            return formatDecimal(
                _.sumBy(this.catalogOrderItems(), (item) => parseDecimal(item.price) * item.quantity),
                session.pyratConf.BUDGETING_DISPLAY_PRECISION);
        });

        this.phenotypicChartPrepared = ko.observable();
        this.healthStatusVerified = ko.observable();
        this.history = ko.observableArray();

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                setTimeout(() => {
                    if (this.params.orderRequestId) {
                        this.originId(seed.order_request_details.origin_id);
                        this.deliveryDate(seed.order_request_details.delivery_date);
                        this.priority(seed.order_request_details.priority);
                        this.ownerId(seed.order_request_details.owner_id);
                        this.responsibleStaffId(seed.order_request_details.responsible_staff_id);
                        this.projectId(seed.order_request_details.project_id);
                        this.speciesId(seed.order_request_details.species_id);
                        this.speciesIdIsUserSelected(false);
                        this.licenseId(seed.order_request_details.licence_id);
                        this.licenseIdIsUserSelected(false);
                        this.classificationId(seed.order_request_details.classification_id);

                        // location
                        if (seed.order_request_details.rack_id) {
                            this.preselectLocation({ type: "rack", id: seed.order_request_details.rack_id });
                        } else if (seed.order_request_details.room_id) {
                            this.preselectLocation({ type: "room", id: seed.order_request_details.room_id });
                        } else if (seed.order_request_details.area_id) {
                            this.preselectLocation({ type: "area", id: seed.order_request_details.area_id });
                        } else if (seed.order_request_details.building_id >= 0) {
                            this.preselectLocation({ type: "building", id: seed.order_request_details.building_id });
                        } else {
                            this.preselectLocation(undefined);
                        }

                        this.cageType(seed.order_request_details.cage_type);
                        this.cageCategoryId(seed.order_request_details.cage_category_id);
                        this.animalsPerCage(seed.order_request_details.animals_per_cage);
                        this.diet(seed.order_request_details.diet);
                        this.strainId(seed.order_request_details.strain_id);
                        this.geneticBackgroundId(seed.order_request_details.genetic_bg_id);
                        this.quantityMales(seed.order_request_details.quantity_male);
                        this.quantityFemales(seed.order_request_details.quantity_female);
                        this.quantityUnknown(seed.order_request_details.quantity_unknown);
                        this.pregnant(seed.order_request_details.pregnant);
                        this.embryonicStage(seed.order_request_details.embryonic_stage === null ? undefined :
                            seed.order_request_details.embryonic_stage);
                        this.plugDate(seed.order_request_details.plug_date);
                        this.withLitter(seed.order_request_details.with_litter);
                        this.litterAge(seed.order_request_details.litter_age);
                        this.age(seed.order_request_details.age);
                        this.ageMax(seed.order_request_details.age_max);
                        this.ageRangeSelected(seed.order_request_details.age_use_range);
                        this.weight(seed.order_request_details.weight);
                        this.weightMax(seed.order_request_details.weight_max);
                        this.weightRangeSelected(seed.order_request_details.weight_use_range);
                        this.phenotypicChartPrepared(seed.order_request_details.phenotypic_chart);
                        this.healthStatusVerified(seed.order_request_details.health_status);
                        this.history(seed.order_request_details.history);

                        // readonly values
                        this.budgetName(seed.order_request_details.budget_name);
                        this.locationString(seed.order_request_details.location_string);

                        // catalog items
                        this.useCatalog(!!seed.order_request_details.use_catalog);
                        this.catalogOrderItems(_.map(seed.order_request_details.catalog_order_items, (item) => {
                            return {
                                "id": item.id,
                                "product_type_label": item.product_type_label,
                                "catalog_item_id": item.catalog_item_id,
                                "catalog_item_label": item.catalog_item_label,
                                "import_date": item.import_date,
                                "price": item.price,
                                "quantity": item.quantity,
                                "show_import_button": item.show_import_button,
                            };
                        }));

                    } else {
                        // new order request
                        this.deliveryDate(formatDate(addWorkDays(new Date(), session.pyratConf.ORDER_REQUEST_DUE_DATE_OFFSET)));
                        this.ownerId(seed.order_request_details.owner_id);
                        this.speciesId(seed.order_request_details.species_id);
                        this.defaultResponsibleBasicFullname(seed.order_request_details.default_responsible_basic_fullname);
                    }

                    // ajax loaded lists
                    this.availableResponsiblesBasic.subscribeOnce(() => {
                        setTimeout(() => {
                            this.responsibleBasicId(seed.order_request_details.responsible_basic_id);
                        }, 0);
                    });

                    // ajax loaded lists are static lists if not editable
                    if (!this.seed().fields.responsible_basic.editable && seed.order_request_details.responsible_basic_id) {
                        const current = [{
                            userid: seed.order_request_details.responsible_basic_id,
                            fullname: seed.order_request_details.responsible_basic_fullname,
                        }];
                        this.availableResponsiblesBasic(current);
                        this.responsibleBasicId(seed.order_request_details.responsible_basic_id);
                    }

                    this.commentWidgetSeed(seed.comment_widget_seed || undefined);

                    // setup user input dependencies
                    this.speciesId.subscribeOnce(() => {
                        this.speciesIdIsUserSelected(true);
                    });
                    this.licenseId.subscribeOnce(() => {
                        this.licenseIdIsUserSelected(true);
                    });
                }, 0);
            }
        });

        /* internals */
        this.selectedTab = ko.observable("comments");
        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);
        this.reloadRequired = ko.observable(false);

        this.errorMessages = ko.pureComputed(() => {
            const res = _.compact([
                this.originId.errorMessage(),
                this.deliveryDate.errorMessage(),
                this.repetitionDates.errorMessage(),
                this.priority.errorMessage(),
                this.ownerId.errorMessage(),
                this.responsibleBasicId.errorMessage(),
                this.responsibleStaffId.errorMessage(),
                this.projectId.errorMessage(),
                this.speciesId.errorMessage(),
                this.licenseId.errorMessage(),
                this.classificationId.errorMessage(),
                this.selectedLocation.errorMessage(),
                this.strainId.errorMessage(),
                this.geneticBackgroundId.errorMessage(),
                this.quantityMales.errorMessage(),
                this.quantityFemales.errorMessage(),
                this.quantityUnknown.errorMessage(),
                this.quantityFilled.errorMessage(),
                this.embryonicStage.errorMessage(),
                this.plugDate.errorMessage(),
                this.embryonicStageOrPlugDateFilled.errorMessage(),
                this.litterAge.errorMessage(),
                this.age.errorMessage(),
                this.ageMax.errorMessage(),
                this.weight.errorMessage(),
                this.weightMax.errorMessage(),
                this.ageRange.errorMessage(),
                this.weightRange.errorMessage(),
                this.ageOrWeightFilled.errorMessage(),
                this.catalogId.errorMessage(),
                this.catalogItemId.errorMessage(),
                this.catalogPrice.errorMessage(),
                this.quantityItems.errorMessage(),
            ]);

            // extend the list with server side error messages
            return res.concat(this.errors() || []);
        });

        this.canSubmit = ko.pureComputed(() => {
            if (this.submitInProgress()) {
                return false;
            } else if (this.originId.isInvalid() ||
                       this.deliveryDate.isInvalid() ||
                       this.repetitionDates.isInvalid() ||
                       this.priority.isInvalid() ||
                       this.ownerId.isInvalid() ||
                       this.responsibleBasicId.isInvalid() ||
                       this.responsibleStaffId.isInvalid() ||
                       this.projectId.isInvalid() ||
                       this.speciesId.isInvalid() ||
                       this.licenseId.isInvalid() ||
                       this.classificationId.isInvalid() ||
                       this.selectedLocation.isInvalid() ||
                       this.strainId.isInvalid() ||
                       this.geneticBackgroundId.isInvalid() ||
                       this.quantityMales.isInvalid() ||
                       this.quantityFemales.isInvalid() ||
                       this.quantityUnknown.isInvalid() ||
                       this.quantityFilled.isInvalid() ||
                       this.embryonicStage.isInvalid() ||
                       this.plugDate.isInvalid() ||
                       this.embryonicStageOrPlugDateFilled.isInvalid() ||
                       this.litterAge.isInvalid() ||
                       this.age.isInvalid() ||
                       this.ageMax.isInvalid() ||
                       this.weight.isInvalid() ||
                       this.weightMax.isInvalid() ||
                       this.ageRange.isInvalid() ||
                       this.weightRange.isInvalid() ||
                       this.ageOrWeightFilled.isInvalid() ||
                       this.comment.isInvalid()) {
                return false;
            }
            return true;
        });
    }

    // style separator in dropdowns

    public styleOriginOptions = (opt: HTMLOptionElement, item: {id: number; name: string; disabled: boolean}) => {
        if (item && item.name === "separator") {
            opt.className = "delimiter";
            opt.disabled = true;
            opt.text = "――――――";
        }
    };

    public styleStrainOptions = (opt: HTMLOptionElement, item: {id: number; name_with_id: string; disabled: boolean}) => {
        if (item && item.name_with_id === "separator") {
            opt.className = "delimiter";
            opt.disabled = true;
            opt.text = getTranslation("Lines / Strains without species");
        }
    };

    public styleLicenceOptions = (opt: HTMLOptionElement, item: {id: number; name: string; disabled: boolean}) => {
        if (item && item.id === -1) {
            opt.className = "delimiter";
            opt.disabled = true;
        }
    };

    public styleClassificationOptions = (opt: HTMLOptionElement, item: {id: number; name: string; disabled: boolean}) => {
        if (item && item.id === -1) {
            opt.className = "delimiter";
            opt.disabled = true;
        }
    };

    public isWrongWeekday = (value: string) => {
        const origin = this.seed()?.origins.find(({ id }) => id === this.originId());
        const allowedWeekdays = origin ? origin.delivery_days_js : undefined;

        if (
            this.isExternalOrder()
            && allowedWeekdays
            && !allowedWeekdays.includes(parseDate(value).getDay())
        ) {
            return getTranslation("Deliveries from this origin do not arrive on this weekday") + ". "
                + getTranslation("Only") + ": " + (origin.delivery_days_labels.join(", ") || getTranslation("Never"));
        }

        return false;
    };

    public showPregnant = () => {
        this.pregnant(true);
        this.embryonicStage(undefined);
        this.plugDate(undefined);
    };

    public hidePregnant = () => {
        this.pregnant(false);
        this.embryonicStage(undefined);
        this.plugDate(undefined);
    };

    public showWithLitter = () => {
        this.withLitter(true);
        this.litterAge(undefined);
    };

    public hideWithLitter = () => {
        this.withLitter(false);
        this.litterAge(undefined);
    };

    public showAgeRange = () => {
        this.ageRangeSelected(true);
        this.ageMax(undefined);
    };

    public hideAgeRange = () => {
        this.ageRangeSelected(false);
        this.ageMax(undefined);
    };

    public showWeightRange = () =>{
        this.weightRangeSelected(true);
        this.weightMax(undefined);
    };

    public hideWeightRange = () => {
        this.weightRangeSelected(false);
        this.weightMax(undefined);
    };

    public reseedComments = () => {
        // reload popup after changes in comment widget
        // because of status 'Pending Comment' in status bar
        this.reloadRequired(true);
        this.seed.forceReload();
    };

    public toggleShowAddCatalogOrderItem = () => {
        this.showAddCatalogOrderItem(!this.showAddCatalogOrderItem());
    };

    public canAddCatalogOrderItem = ko.pureComputed(() => {
        return !(this.catalogId.isInvalid() ||
                 this.catalogItemId.isInvalid() ||
                 this.catalogPrice.isInvalid() ||
                 this.quantityItems.isInvalid());
    });

    public addCatalogOrderItem = () => {
        this.catalogOrderItems.push({
            "product_type_label": _.find(this.seed().product_types, { id: this.productType() }).name,
            "catalog_item_id": this.catalogItemId(),
            "catalog_item_label": _.find(this.seed().catalog_items, { id: this.catalogItemId() }).catalog_item_label,
            "import_date": "",
            "price": this.catalogPrice(),
            "quantity": this.quantityItems(),
            "show_import_button": false,
        });
    };

    public removeCatalogOrderItem = (item: CatalogOrderItem) => {
        this.catalogOrderItems.remove(item);
    };

    public importCatalogOrderItem = (item: CatalogOrderItem) => {
        this.reloadRequired(false);
        mainMenu.open("animal_import", {
            order_request_id: this.params.orderRequestId,
            order_request_item_id: item.id,
        });
    };

    public setStatus = (newStatus: string, confirmMessage: string) => {
        if (confirmMessage && !confirm(confirmMessage)) return;
        this.submitInProgress(true);
        this.errors([]);

        const form = getFormData({ request: JSON.stringify({
            request_id: this.params.orderRequestId,
            new_status: newStatus,
        }) });
        fetch(cgiScript("order_request_detail.py"), { method: "POST", body: form })
            .then(response => response.json())
            .then(response => {
                this.submitInProgress(false);
                if (response.success) {
                    this.reloadRequired(true);
                    // reload popup
                    this.seed.forceReload();
                    // show success message
                    notifications.showNotification(response.message, "success");
                } else {
                    // validation errors
                    this.submitInProgress(false);
                    this.errors.push(response.message);
                }
            })
            .catch(() => this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again.")))
            .finally(() => this.submitInProgress(false));
    };

    public submitForm = (action: "create" | "update") => {
        const postData: SubmitData = {
            request_id: this.params.orderRequestId,
            origin_id: this.originId(),
            delivery_date: this.deliveryDate(),
            priority: this.priority(),
            owner_id: this.ownerId(),
            responsible_basic_id: this.responsibleBasicId(),
            responsible_staff_id: this.responsibleStaffId(),
            species_id: this.speciesId(),
            strain_id: !this.useCatalog() ? this.strainId() : undefined,
            project_id: this.projectId(),
            genetic_bg_id: this.geneticBackgroundId(),
            licence_id: this.licenseId(),
            classification_id: this.classificationId(),
            licence_overuse_limit: this.licenceOveruseLimit()  ? 1 : undefined,
            use_catalog: this.useCatalog() ? 1 : undefined,
            quantity_male: this.quantityMales(),
            quantity_female: this.quantityFemales(),
            quantity_unknown: this.quantityUnknown(),
            pregnant: this.pregnant() ? 1 : undefined,
            embryonic_stage: this.embryonicStage(),
            plug_date: this.plugDate(),
            with_litter: this.withLitter() ? 1 : undefined,
            litter_age: this.litterAge(),
            animals_per_cage: this.animalsPerCage(),
            cage_type: this.cageType(),
            cage_category_id: this.cageCategoryId(),
            age: this.age() || "",
            age_max: this.ageMax() || "",
            age_use_range: this.ageRangeSelected() ? 1 : undefined,
            weight: this.weight() || "",
            weight_max: this.weightMax() || "",
            weight_use_range: this.weightRangeSelected() ? 1 : undefined,
            diet: this.diet() || "",
            building_id: this.buildingId() || undefined,
            area_id: this.areaId() || undefined,
            room_id: this.roomId() || undefined,
            rack_id: this.rackId() || undefined,
            phenotypic_chart: this.phenotypicChartPrepared() ? 1 : undefined,
            health_status: this.healthStatusVerified() ? 1 : undefined,
        };

        // catalog order
        if (this.useCatalog()) {
            postData["catalog_order_items"] = JSON.stringify(
                _.map(this.catalogOrderItems(), (row) => {
                    return {
                        id: row.id,
                        catalog_item_id: row.catalog_item_id,
                        quantity: row.quantity,
                        price: row.price,
                    };}));
        }

        if (action === "create") {
            postData["create_order_request"] = "1";

            // comment field on new request
            postData["comment"] = JSON.stringify(this.comment());

            // repeated instances
            if (this.repeatedDeliveryDatesVisible()) {
                postData["repeated_delivery_dates"] = JSON.stringify(
                    _.map(this.repetitionDates(), ko.utils.unwrapObservable));
            }

        } else if (action === "update") {
            postData["update_order_request"] = "1";
        } else {
            return; // action unknown, don't post
        }

        this.errors([]);
        this.submitInProgress(true);
        this.showLicenceOveruseLimit(false);

        const form = getFormData({ request: JSON.stringify(postData) });
        fetch(cgiScript("order_request_detail.py"), { method: "POST", body: form })
            .then(response => response.json())
            .then(response => {
                this.submitInProgress(false);
                if (response.success) {
                    this.reloadRequired(true);
                    if (action === "create") {
                        // close popup
                        this.dialog.close();
                    } else {
                        // reload popup
                        this.seed.forceReload();
                    }
                    // show success message
                    notifications.showNotification(response.message, "success");
                } else {
                    // validation errors
                    this.submitInProgress(false);
                    this.errors.push(response.message);

                    if (response.confirm === "confirm_licence_overuse") {
                        this.showLicenceOveruseLimit(true);
                    }
                }
            })
            .catch(() => this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again.")))
            .finally(() => this.submitInProgress(false));
    };

    public deleteRequest = () => {
        this.errors([]);
        this.submitInProgress(true);

        notifications.showConfirm(
            getTranslation("Are you sure you want to delete this order?"),
            () => {
                const form = getFormData({ request: JSON.stringify({
                    request_id: this.params.orderRequestId,
                    delete_order_request: "1",
                }) });
                fetch(cgiScript("order_request_detail.py"), { method: "POST", body: form })
                    .then(response => response.json())
                    .then(response => {
                        this.submitInProgress(false);
                        if (response.success) {
                            // close popup
                            this.reloadRequired(true);
                            this.dialog.close();

                            // show success message
                            notifications.showNotification(response.message, "success");
                        } else {
                            // validation errors
                            this.submitInProgress(false);
                            this.errors.push(response.message);
                        }
                    })
                    .catch(() => this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again.")))
                    .finally(() => this.submitInProgress(false));
            },
        );
    };

    public printRequest = () => {
        window.open(getUrl(cgiScript("order_request_detail.py"), {
            request_id: this.params.orderRequestId,
            print: 1,
        }), "_blank");
    };

    public openImportPage = () => {
        this.reloadRequired(false);
        mainMenu.open("animal_import", { order_request_id: this.params.orderRequestId });
    };

    public openAnimalList = () => {
        this.errors([]);
        fetch(getUrl(cgiScript("order_request_detail.py"), {
            request_id: this.params.orderRequestId,
            select_animals: 1,
        },  { absoluteUrl: true }))
            .then(response => response.json())
            .then(response => {
                if (response.success && response.animal_filter) {
                    this.reloadRequired(false);
                    mainMenu.openAndResetListFilter("get_animal_list", response.animal_filter);
                } else {
                    // validation errors
                    this.errors.push(response.message);
                }
            })
            .catch(() => this.errors.push(getTranslation("General unexpected error occurred.")));
    };
}

export const showOrderRequestDetails = dialogStarter(OrderRequestDetailsViewModel, template, {
    name: "OrderRequestDetails",
    width: 590,
    anchor: { top: 5, right: 5 },
    closeOthers: true,
});
