import {
    Computed,
    Subscribable,
    PureComputed,
    Observable,
    ObservableArray,
    observable,
    observableArray,
    pureComputed,
    computed,
    Unwrapped,
} from "knockout";
import * as ko from "knockout";
import * as _ from "lodash";

import {
    LicenseClassificationOption,
    LicenseClassificationOptionDelimiter,
    LicenseOption,
    LicensesService,
    TankQuickselectSetSpeciesActionSeed,
    TanksService,
} from "../backend/v1/";
import { NewComment } from "../knockout/components/commentWidget";
import {
    PreselectLocationItem,
    LocationItem,
} from "../knockout/components/locationPicker/locationPicker";
import { TankPosition } from "../knockout/components/locationPicker/tankPicker";
import { htmlDialogStarter } from "../knockout/dialogStarter";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import { CheckExtended } from "../knockout/extensions/invalid";
import { writeException } from "../lib/excepthook";
import { showColumnSelect } from "../lib/listView";
import { getTranslation } from "../lib/localize";
import { HtmlDialog } from "../lib/popups";
import { session } from "../lib/pyratSession";
import {
    mainMenu,
    frames,
} from "../lib/pyratTop";
import {
    ArrayElement,
    cgiScript,
    ValueOf,
    getUrl,
    isInvalidCalendarDate,
} from "../lib/utils";

import { showSetLicense } from "./setLicense";
import template from "./tankQuickselect.html";


import "/scss/quick_select.scss";

// Obtain type from backend service
type SeedType = Awaited<ReturnType<typeof TanksService.getQuickselectActionsSeed>>;
type ExecuteResponseType = Awaited<ReturnType<typeof TanksService.executeQuickselectActions>>;

interface Context {
    tank_id: number;
    status_label: string;
    final_state_period: number;
    number_of_male: number;
    number_of_female: number;
    number_of_unknown: number;
    age_level: string;
    had_recent_crossing?: any;
    tank_location_id: number;
    tank_position: string;
    tank_rack_id: number;
    location_rack_name: string;
    species_id: number;
    strain_id?: any;
    strain_name_with_id?: any;
    mutations: any[];
    owner_id: number;
    owner_fullname: string;
    responsible_id?: any;
    had_license: number;
    license_assign_history: any[];
    classification_id: number;
    projects: any[];
    medical_conditions: any[];
    alive_count: number;
    status: string;
    location_row_span: boolean;
    age_level_label: string;
    had_procedure: boolean;
}

interface SelectableContext extends Context {
    selected: Observable<boolean>;
}

type AssignCrossingStrainType =
    { id: "new" | "known"; label: string } |
    { id: "parent"; value: string; label: string };

interface Response {
    success: boolean;
    context: {
        tank_id: number[];
    };
    content: {
        assign_crossing_action?: {
            crossing_id: number;
        };
        print_tank_action?: any;
        export_tank_action?: any;
        add_to_selection_action?: [];
        manage_licenses_action?: {
            tank_id: number[];
        };
    };
}

interface Params {
    tankIds: number[];
    actions?: string[];
    closeCallback?: () => void;
    reloadCallback?: () => void;
    workrequestId?: number;
    closeWorkrequest?: boolean;
}

abstract class Action {

    public qs: TankQuickselectViewModel;
    public requireConclusion = true;
    public selected: Subscribable<boolean>;
    public enabled: Subscribable<boolean>;
    public valid: Subscribable<boolean>;
    public possible: Subscribable<boolean>;
    public errors: ObservableArray<string>;

    protected constructor(qs: TankQuickselectViewModel) {
        this.qs = qs;
        this.selected = ko.observable(false);
        this.enabled = ko.observable(true);
        this.valid = ko.observable(true);
        this.errors = ko.observableArray([]);
        this.possible = ko.observable(true);  // TODO: tank state check required?
    }

    public toggleSelected = () => {
        this.selected(!this.selected());
    };

    public serialize = () => {
        return {};
    };
}


// TODO: Waiting for "inherit" from in TypeScript to replace "Action" return value with implementation.
// noinspection JSPotentiallyInvalidUsageOfThis
const actionModels: {
    [key in keyof Omit<SeedType["content"], "set_number_of_animals_action">]: (
        qs: TankQuickselectViewModel,
        seed: SeedType["content"][key],
    ) => Action;
} = {
    // The "set_number_of_animals_action" is used from a separate dialog only,
    // but was implemented here to share some code for the seed.
    separate_animals_action: (qs) =>
        new (class extends Action {
            public forExperiment: Observable<boolean>;
            public selectedContext: typeof qs.selectedContext;
            public splits: ObservableArray<{
                tank: SelectableContext;
                countMale: CheckExtended<Observable<string>>;
                countFemale: CheckExtended<Observable<string>>;
                countUnknown: CheckExtended<Observable<string>>;
                maxMale: PureComputed<number>;
                maxFemale: PureComputed<number>;
                maxUnknown: PureComputed<number>;
                newAliveCount: PureComputed<number>;
                isInvalid: PureComputed<boolean>;
            }> = observableArray([]);
            public lastTankSplit: { [tank_id: number]: PureComputed<Unwrapped<typeof this.splits>[0] | null> };
            public remainingAnimals: {
                [tank_id: number]: {
                    male: PureComputed<number>;
                    female: PureComputed<number>;
                    unknown: PureComputed<number>;
                    combined: PureComputed<number>;
                };
            };
            public anyRemainingToSelect: PureComputed<boolean>;

            public addSplit = (tank: SelectableContext) => {
                const lastTankSplit = this.lastTankSplit[tank.tank_id]();
                const newMaleCount = lastTankSplit
                    ? String(
                        Math.min(
                            parseInt(lastTankSplit.countMale(), 10),
                            Math.max(this.remainingAnimals[tank.tank_id].male(), 0),
                        ),
                    )
                    : "0";
                const newFemaleCount = lastTankSplit
                    ? String(
                        Math.min(
                            parseInt(lastTankSplit.countFemale(), 10),
                            Math.max(this.remainingAnimals[tank.tank_id].female(), 0),
                        ),
                    )
                    : "0";
                const newUnknownCount = lastTankSplit
                    ? String(
                        Math.min(
                            parseInt(lastTankSplit.countUnknown(), 10),
                            Math.max(this.remainingAnimals[tank.tank_id].unknown(), 0),
                        ),
                    )
                    : "0";
                const countMale = observable(newMaleCount).extend({
                    invalid: (v) =>
                        !(  v
                            && String(v).match(/^\d+$/) &&
                            parseInt(v, 10) >= 0 &&
                            (parseInt(v, 10) < tank.number_of_male || tank.number_of_male == 0) &&
                            this.remainingAnimals[tank.tank_id].male() >= 0
                        ),
                });
                const countFemale = observable(newFemaleCount).extend({
                    invalid: (v) =>
                        !(
                            v &&
                            String(v).match(/^\d+$/) &&
                            parseInt(v, 10) >= 0 &&
                            (parseInt(v, 10) < tank.number_of_female || tank.number_of_female == 0) &&
                            this.remainingAnimals[tank.tank_id].female() >= 0
                        ),
                });
                const countUnknown = observable(newUnknownCount).extend({
                    invalid: (v) =>
                        !(
                            v &&
                            String(v).match(/^\d+$/) &&
                            parseInt(v, 10) >= 0 &&
                            (parseInt(v, 10) < tank.number_of_unknown || tank.number_of_unknown == 0) &&
                            this.remainingAnimals[tank.tank_id].unknown() >= 0
                        ),
                });
                const maxMale = pureComputed(() => {
                    return parseInt(countMale(), 10) + this.remainingAnimals[tank.tank_id].male();
                });
                const maxFemale = pureComputed(() => {
                    return parseInt(countFemale(), 10) + this.remainingAnimals[tank.tank_id].female();
                });
                const maxUnknown = pureComputed(() => {
                    return parseInt(countUnknown(), 10) + this.remainingAnimals[tank.tank_id].unknown();
                });
                this.splits.push({
                    tank,
                    countMale,
                    countFemale,
                    countUnknown,
                    maxMale,
                    maxFemale,
                    maxUnknown,
                    newAliveCount: pureComputed(
                        () =>
                            parseInt(countMale(), 10) +
                            parseInt(countFemale(), 10) +
                            parseInt(countUnknown(), 10),
                    ),
                    isInvalid: pureComputed(() =>
                        parseInt(countMale(), 10) +
                        parseInt(countFemale(), 10) +
                        parseInt(countUnknown(), 10) == 0,
                    ),
                });
            };

            /** Add new splits, based on the last added split, until no more animals are left. **/
            public exhaustSplit = (tank: SelectableContext) => {
                const remainingAnimals = this.remainingAnimals[tank.tank_id];
                const lastTankSplit = this.lastTankSplit[tank.tank_id]();
                let exitCount = 1000;  // Prevent infinite loops.
                if (parseInt(lastTankSplit.countMale(), 10) && remainingAnimals.male()) {
                    while (exitCount && remainingAnimals.male() > 0) {
                        this.addSplit(tank);
                        exitCount--;
                    }
                }
                if (parseInt(lastTankSplit.countFemale(), 10) && remainingAnimals.female()) {
                    while (exitCount && remainingAnimals.female() > 0) {
                        this.addSplit(tank);
                        exitCount--;
                    }
                }
                if (parseInt(lastTankSplit.countUnknown(), 10) && remainingAnimals.unknown()) {
                    while (exitCount && remainingAnimals.unknown() > 0) {
                        this.addSplit(tank);
                        exitCount--;
                    }
                }
            };

            public removeSplit = (split: any) => {
                this.splits.remove(split);
            };

            constructor() {
                super(qs);
                this.selectedContext = qs.selectedContext;
                this.forExperiment = observable(false);

                this.remainingAnimals = qs.context().reduce((remainig: typeof this.remainingAnimals, t) => {
                    const male = pureComputed(() => {
                        return t.number_of_male - this.splits().reduce((usedMale, s) => {
                            if (s.tank.tank_id === t.tank_id) {
                                return usedMale + parseInt(s.countMale(), 10);
                            } else {
                                return usedMale;
                            }
                        }, 0);
                    });
                    const female = pureComputed(() => {
                        return t.number_of_female - this.splits().reduce((usedFeale, s) => {
                            if (s.tank.tank_id === t.tank_id) {
                                return usedFeale + parseInt(s.countFemale(), 10);
                            } else {
                                return usedFeale;
                            }
                        }, 0);
                    });
                    const unknown = pureComputed(() => {
                        return t.number_of_unknown - this.splits().reduce((usedUnknown, s) => {
                            if (s.tank.tank_id === t.tank_id) {
                                return usedUnknown + parseInt(s.countUnknown(), 10);
                            } else {
                                return usedUnknown;
                            }
                        }, 0);
                    });
                    remainig[t.tank_id] = {
                        male,
                        female,
                        unknown,
                        combined: pureComputed(() => male() + female() + unknown()),
                    };
                    return remainig;
                }, {});

                this.anyRemainingToSelect = pureComputed(() => {
                    return _.some(qs.selectedContext().map((t) => {
                        return this.remainingAnimals[t.tank_id].combined() > 1;
                    }));
                });

                // last split for each tank
                this.lastTankSplit = qs.context().reduce((result: typeof this.lastTankSplit, tank) => {
                    result[tank.tank_id] = pureComputed(() => this.splits()
                        .filter((s) => s.tank.tank_id === tank.tank_id)
                        .pop());
                    return result;
                }, {});

                this.valid = computed(() => {
                    this.errors.removeAll();

                    // no splits defined
                    if (_.reduce(this.splits(), (m, t) => m + t.newAliveCount(), 0) < 1) {
                        return false;
                    }

                    // split defined for tank which is not selected
                    for (const split of this.splits()) {
                        if (!split.tank.selected()) {
                            return false;
                        }
                    }

                    // split with invalid counts
                    return _.every(this.splits(), (t) => {
                        return (
                            t.countMale.isValid() &&
                            t.countFemale.isValid() &&
                            t.countUnknown.isValid() &&
                            !t.isInvalid()
                        );
                    });

                });
            }

            public serialize = () => ({
                splits: _.map(this.splits(), (s) => {
                    return {
                        tank_id: s.tank.tank_id,
                        number_of_male: s.countMale(),
                        number_of_female: s.countFemale(),
                        number_of_unknown: s.countUnknown(),
                    };
                }),
                for_experiment: this.forExperiment(),
            });

        }),

    move_tank_action: (qs, seed) => new (class extends Action {

        public target: Observable<string>;
        public possibleTargets: ({ name: "new" | "existing"; label: string })[];
        public selectedLocation: Observable<LocationItem>;
        public selectedLocationTitle: Observable<string>;
        public preselectLocation: PreselectLocationItem;
        public rackLocation: PureComputed<LocationItem>;
        public newTankPosition: CheckExtended<Observable<string>>;
        public selectedPosition: Observable<TankPosition>;
        public joinTankId: Observable<number>;
        public occupiedPositions: FetchBackendExtended<Observable<string[]>>;
        private selectedPositionPossibleJoiners: PureComputed<TankPosition["tanks"]>;
        private readonly parsedNewTankPositions: PureComputed<string[]>;

        constructor() {
            super(qs);
            this.target = ko.observable("existing");
            this.possibleTargets = [
                { name: "new", label: getTranslation("Manual position in rack.") },
                { name: "existing", label: getTranslation("Known position or together with other record.") },
            ];

            this.selectedLocation = ko.observable();
            this.preselectLocation = seed.common_location;
            this.selectedLocationTitle = ko.observable();
            this.rackLocation = ko.pureComputed(() => {
                const location = this.selectedLocation();
                if (location && location.type === "rack") return location;
            });

            this.newTankPosition = ko.observable();


            this.joinTankId = ko.observable();
            this.selectedPosition = ko.observable();
            this.selectedPosition.subscribe(() => {
                this.newTankPosition(undefined);
                this.joinTankId(undefined);
            });

            this.selectedPositionPossibleJoiners = ko.pureComputed(() => {
                const criteria = ["strain_id"];

                // only check if a context tank is selected
                if (!qs.selectedContext() || qs.selectedContext().length < 1) {
                    return [];
                }

                // only check if a target tank is selected
                if (!this.selectedPosition() || this.selectedPosition().tanks.length < 1) {
                    return [];
                }

                // deny if the selected context is not similar in all criteria
                if (_.keys(_.groupBy(qs.selectedContext(), (t) => {
                    return _.pick(t, criteria);
                })).length > 1) {
                    return [];
                }

                // deny if selected context had a license assignment or a procedure
                if (_.some(qs.selectedContext(), (t) => {
                    return t.had_license || t.had_procedure;
                })) {
                    return [];
                }

                // keep only tanks that are very similar but not in the selected context
                // and had no license assignment and no procedures
                return _.filter(this.selectedPosition().tanks, (t) => {
                    return _.isEqual(_.pick(t, criteria), _.pick(qs.selectedContext()[0], criteria))
                        && !_.includes(_.map(qs.selectedContext(), "tank_id"), t.tank_id)
                        && !t.had_license
                        && !t.had_procedure;
                });

            });

            this.parsedNewTankPositions = ko.pureComputed(() => {

                let splits: string[] = [null];

                if (_.isString(this.newTankPosition()) && _.size(this.newTankPosition())) {
                    splits = this.newTankPosition().split(/[ ,]+/);
                    if (!_.every(splits, _.identity)) {
                        // e.g. empty strings found
                        return [];
                    }
                }

                if (splits.length === 1) {
                    // for single position label, return this label repeated
                    return _.times(qs.selectedContext().length, _.constant(splits[0]));
                } else if (splits.length === qs.selectedContext().length) {
                    // if there is one position label for each tank, use them
                    return splits;
                }

            }).extend({ rateLimit: { timeout: 500, method: "notifyWhenChangesStop" } });

            this.occupiedPositions = ko.observable().extend({
                fetchBackend: () => {
                    if (this.selected()
                            && this.target() === "new"
                            && this.rackLocation()
                            && this.parsedNewTankPositions()
                    ) {
                        return TanksService.isPositionOccupied({
                            "status": "open",
                            "tankPosition": this.parsedNewTankPositions(),
                            "rackId": this.rackLocation().db_id,
                        });
                    }
                },
            });

            this.newTankPosition.extend({
                invalid: () => {
                    return !this.parsedNewTankPositions();
                },
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();
                if (this.target() === "existing") {
                    return !!_.get(this.selectedPosition(), "location_reference");
                } else if (this.target() === "new") {
                    return Boolean(this.rackLocation()) && this.newTankPosition.isValid();
                }
            });
        }

        public serialize = () => {
            if (this.target() === "existing") {
                return {
                    target: "existing",
                    rack_id: this.rackLocation().db_id,
                    tank_positions: _.times(
                        qs.selectedContext().length,
                        _.constant(_.get(this.selectedPosition(), "tank_position"))),
                    join_tank_id: this.joinTankId(),
                };
            } else if (this.target() === "new") {
                return {
                    target: "new",
                    rack_id: this.rackLocation().db_id,
                    tank_positions: this.parsedNewTankPositions() || false,
                };
            }

        };

    }),

    set_age_level_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public ageLevel: Observable<string>;

        constructor() {
            super(qs);
            this.ageLevel = ko.observable();

            this.ageLevel.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.selected() && !this.ageLevel()) {
                    const msg = getTranslation("Please select an age level");

                    this.errors.push(msg);
                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({
            age_level: this.ageLevel(),
        });
    }),

    set_tank_type_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public tankType: Observable<string>;

        constructor() {
            super(qs);
            this.tankType = ko.observable();
            this.tankType.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.selected() && !this.tankType()) {
                    const msg = getTranslation("Please select a tank type");

                    this.errors.push(msg);
                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({
            tank_type: this.tankType(),
        });

    }),

    set_release_date_action: (qs) => new (class extends Action {

        public dateOfBirth: Observable<string>;
        public dateOfRelease: Observable<string>;

        constructor() {
            super(qs);
            this.dateOfBirth = ko.observable();
            this.dateOfRelease = ko.observable();

            this.dateOfBirth.subscribe(() => {
                this.selected(true);
            });

            this.dateOfRelease.subscribe(() => {
                this.selected(true);
            });


            this.valid = ko.computed(() => {

                if (this.dateOfBirth() && isInvalidCalendarDate(this.dateOfBirth())) {
                    return false;
                }

                if (this.dateOfRelease() && isInvalidCalendarDate(this.dateOfRelease())) {
                    return false;
                }

                if (this.selected() && !(this.dateOfBirth() || this.dateOfRelease())) {
                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({
            date_of_birth: this.dateOfBirth() || null,
            date_of_release: this.dateOfRelease() || null,
        });

    }),

    add_projects_action: (qs, seed) => new class extends Action {

        public seed = seed;
        public projectIds: ObservableArray<number>;

        constructor() {
            super(qs);

            this.projectIds = ko.observableArray();
            this.projectIds.subscribe((projectIds) => {
                if (projectIds && projectIds.length) {
                    this.selected(true);
                }
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.selected() && !this.projectIds()?.length) {
                    this.errors.push(getTranslation("Please select a project"));
                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({
            project_ids: this.projectIds(),
        });

    },

    remove_projects_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public projectIds: ObservableArray<number>;

        constructor() {
            super(qs);

            this.projectIds = ko.observableArray();
            this.projectIds.subscribe((projectIds) => {
                if (projectIds && projectIds.length) {
                    this.selected(true);
                }
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.selected() && !this.projectIds()?.length) {
                    this.errors.push(getTranslation("Please select a project"));
                    return false;
                }

                return true;
            });

            this.serialize = () => {
                return { project_ids: this.projectIds() };
            };
        }

    }),

    set_project_action: (qs, seed) => new(class extends Action {

        public seed = seed;
        public projectId: Observable<number>;

        constructor() {
            super(qs);

            this.projectId = ko.observable();
            this.projectId.subscribe(() => {
                this.selected(true);
            });

            this.serialize = () => ({
                project_id: this.projectId(),
            });
        }

    }),

    set_tank_label_action: (qs) => new (class extends Action {

        public tankLabel: Observable<string>;

        constructor() {
            super(qs);
            this.tankLabel = ko.observable();
            this.tankLabel.subscribe(() => {
                this.selected(true);
            });
        }

        public serialize = () => ({
            tank_label: this.tankLabel(),
        });

    }),

    set_tank_generation_action: (qs) => new (class extends Action {

        public generation: Observable<string>;

        constructor() {
            super(qs);
            this.generation = ko.observable();
            this.generation.subscribe(() => {
                this.selected(true);
            });
        }

        public serialize = () => ({
            generation: this.generation(),
        });

    }),

    set_species_action: (qs, seed) => new (
        class extends Action {

            public seed: TankQuickselectSetSpeciesActionSeed = seed;
            public speciesId: Observable<number> = ko.observable();

            constructor() {
                super(qs);

                this.speciesId.subscribe(() => this.selected(true));

                this.valid = ko.computed(() => {
                    this.errors.removeAll();

                    if (this.selected() && !this.speciesId()) {
                        this.errors.push(getTranslation("Please select a species"));
                        return false;
                    }

                    return true;
                });
            }

            public serialize = () => ({
                "species_id": this.speciesId(),
            });
        }
    ),

    set_strain_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public strainId: Observable<number>;

        constructor() {
            super(qs);

            this.strainId = ko.observable();
            this.strainId.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() && !this.strainId()) {
                    this.errors.push(getTranslation("Please select a Line / Strain"));
                    return false;
                }

                return true;
            });

        }

        public serialize = () => ({
            "strain_id": this.strainId(),
        });

    }),

    add_medical_conditions_action: (qs, seed) => new class extends Action {

        public medicalConditions: typeof seed.medical_conditions;
        public medicalConditionIds: ObservableArray<number>;

        constructor() {
            super(qs);

            this.medicalConditions = seed?.medical_conditions?.filter((medicalCondition) => {
                return medicalCondition.available;
            }) || [];

            this.medicalConditionIds = ko.observableArray();
            this.medicalConditionIds.subscribe((medicalConditionIds) => {
                if (medicalConditionIds && medicalConditionIds.length) {
                    this.selected(true);
                }
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.selected() && !this.medicalConditionIds()?.length) {
                    this.errors.push(getTranslation("Please select a condition"));
                    return false;
                }

                return true;
            });
        }

        public serialize = () => ({
            medical_condition_ids: this.medicalConditionIds(),
        });

    },

    remove_medical_conditions_action: (qs, seed) => new (class extends Action {

        public medicalConditions: PureComputed<typeof seed.medical_conditions>;
        public medicalConditionIds: ObservableArray<number>;

        constructor() {
            super(qs);

            this.medicalConditions = ko.pureComputed(() => {
                const medicalConditionIds = _.chain(qs.selectedContext()).map((tank) => {
                    return _.map(tank.medical_conditions, (medicalCondition) => {
                        return medicalCondition.id;
                    });
                }).flatten().uniq().value();

                return seed?.medical_conditions?.filter((medicalCondition) => {
                    return medicalCondition.available || _.includes(medicalConditionIds, medicalCondition.id);
                }) || [];
            });

            this.medicalConditionIds = ko.observableArray();
            this.medicalConditionIds.subscribe((medicalConditionIds) => {
                if (medicalConditionIds && medicalConditionIds.length) {
                    this.selected(true);
                }
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.selected() && !this.medicalConditionIds()?.length) {
                    this.errors.push(getTranslation("Please select a condition"));
                    return false;
                }

                return true;
            });

            this.serialize = () => {
                return { medical_condition_ids: this.medicalConditionIds() };
            };
        }

    }),

    add_procedure_action: (qs, seed) => new (class extends Action {

        public availableProcedures: ValueOf<typeof seed.available_procedures>;
        public initialProcedureId: number;
        public availableClassifications: { classification_id: number; classification_name: string; license_number: string }[];
        public initialClassificationId: number;
        public inputFieldsValid: Observable<boolean>;
        public errorMessage: Observable<string>;

        constructor() {
            super(qs);

            const selectedClassificationIds = _.map(qs.selectedContext(), "classification_id");
            const commonClassificationIds = qs.selectedContext().length ?
                _.map(_.filter(qs.selectedContext()[0].license_assign_history, (tankLicense) => {
                    return _.every(qs.selectedContext(), (otherTank) => {
                        return _.some(otherTank.license_assign_history, (otherTankLicense) => {
                            return otherTankLicense.classification_id === tankLicense.classification_id;
                        });
                    });
                }), (commonClassification) => {
                    return {
                        classification_id: commonClassification.classification_id,
                        classification_name: commonClassification.classification_name,
                        license_number: commonClassification.licence_number,
                    };
                }) : [];

            this.availableProcedures = qs.selectedContext().length ?
                _.filter(seed?.available_procedures[qs.selectedContext()[0].tank_id], (procedure) => {
                    // whether in all other selected tank procedures it is there too
                    return _.every(qs.selectedContext(), (otherTank) => {
                        return _.some(seed?.available_procedures[otherTank.tank_id], (otherTankProcedure) => {
                            return procedure.id === otherTankProcedure.id;
                        });
                    });
                }) : [];
            this.initialProcedureId = seed?.workrequest_details?.procedure_id;
            this.availableClassifications = commonClassificationIds;
            this.initialClassificationId = _.every(selectedClassificationIds, (classificationId) => classificationId === selectedClassificationIds[0]) ?
                selectedClassificationIds[0] : undefined;

            this.inputFieldsValid = ko.observable();
            this.errorMessage = ko.observable();

            if (seed?.workrequest_details?.behavior_name == "procedure") {
                this.selected(true);
            }

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.selected() && !this.inputFieldsValid()) {
                    if (this.errorMessage()) {
                        this.errors.push(this.errorMessage());
                    }

                    return false;
                }

                return true;
            });

            this.serialize = ko.observable({});
        }

    }),

    set_mutations_action: (qs, seed) => new class extends Action {

        public seed = seed;

        public mutations: ObservableArray<{
            mutation_id: number;
            mutation_name: string;
            mutation_grade_id: Observable<number>;
            mutation_grade_name: string;
            available_grades: ArrayElement<ValueOf<typeof seed.available_mutations>>["grades"];
        }>;

        public availableMutations: Computed<ValueOf<typeof seed.available_mutations>>;
        public remainingMutations: Computed<ValueOf<typeof seed.available_mutations>>;
        public selectedMutation: Observable<ArrayElement<ValueOf<typeof seed.available_mutations>>>;
        public selectedGrade: Observable<ArrayElement<ArrayElement<ValueOf<typeof seed.available_mutations>>["grades"]>>;

        constructor() {
            super(qs);
            this.mutations = ko.observableArray([]);
            this.selectedMutation = ko.observable();
            this.selectedGrade = ko.observable();

            this.availableMutations = ko.computed(() => {
                // get the ids of all selected strains
                const selectedStrainIds = _.map(qs.selectedContext(), "strain_id");

                // find mutations that could be assigned for every selected tank strain
                const strainMutations = _.values(_.pick(seed.available_mutations, selectedStrainIds));

                // deep pluck the mutation ids to make later comparison easier
                const strainMutationIds = _.map(strainMutations, (m) => {
                    return _.map(m, "id");
                });

                // find mutations available for all selected tanks
                const commonMutationIds = _.intersection.apply(null, strainMutationIds);

                // return the mutations that are available to all selected tank strains
                return _.chain(strainMutations)
                    .flattenDeep()
                    // @ts-expect-error: Type declaration is wrong for .uniq
                    .uniq(false, (m) => {
                        return m.id;
                    })
                    .filter((m) => {
                        return _.includes(commonMutationIds, m.id);
                    })
                    .value();
            });

            this.remainingMutations = ko.computed(() => {
                return _.filter(this.availableMutations(), (m) => {
                    return _.filter(this.mutations(), { mutation_id: m.id }).length === 0;
                });
            });

            // quick add mutation by selection the grade
            this.selectedGrade.subscribe((v) => {
                if (v) this.addMutation();
            });

            // obtain the mutation from selected tanks
            ko.computed(() => {
                _.forEach(qs.selectedContext(), (t) => {
                    _.forEach(t.mutations, (m) => {
                        const mutation = _.find(this.remainingMutations.peek(), { id: m.mutation_id });

                        if (mutation) {
                            const editableGrade = ko.observable(m.mutation_grade_id || undefined);
                            editableGrade.subscribe((gradeId) => {
                                this.updateMutationGrade(m.mutation_id, gradeId);
                            });

                            this.mutations.push({
                                mutation_id: m.mutation_id,
                                mutation_name: m.mutation_name,
                                mutation_grade_id: editableGrade,
                                mutation_grade_name: m.mutation_grade_name,
                                available_grades: _.get(mutation, "grades"),
                            });
                        }
                    });
                });
            });
        }

        public addMutation = () => {
            const mutationId = _.get(this.selectedMutation(), "id");
            const mutationGradeId = _.get(this.selectedGrade(), "id");
            const editableGrade = ko.observable(mutationGradeId);

            editableGrade.subscribe((gradeId) => {
                this.updateMutationGrade(mutationId, gradeId);
            });

            this.mutations.push({
                mutation_id: mutationId,
                mutation_name: _.get(this.selectedMutation(), "name"),
                mutation_grade_id: editableGrade,
                mutation_grade_name: _.get(this.selectedGrade(), "name"),
                available_grades: _.get(this.selectedMutation(), "grades"),
            });

            this.selected(true);
        };

        public updateMutationGrade = (mutationId: number, gradeId: number) => {
            _.map(this.mutations(), (mutation) => {
                if (mutation.mutation_id === mutationId) {
                    const grade = _.find(mutation.available_grades, { id: gradeId });

                    if (grade) {
                        mutation.mutation_grade_name = grade.name;
                    }
                }
            });

            this.selected(true);
        };

        public removeMutation = (data: any) => {
            this.selected(true);
            this.selectedGrade(undefined);
            this.mutations(_.without(this.mutations(), data));
        };

        public serialize = () => ({
            mutations: _.map(this.mutations(), (m) => {
                return {
                    mutation_id: m.mutation_id,
                    mutation_grade_id: m.mutation_grade_id(),
                };
            }),
        });
    },

    set_responsible_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public responsibleId: Observable<number>;

        constructor() {
            super(qs);
            this.responsibleId = ko.observable();
            this.responsibleId.subscribe(() => this.selected(true));
        }

        public serialize = () => ({
            responsible_id: this.responsibleId(),
        });

    }),

    set_color_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public selectedColor: Observable<string>;

        constructor() {
            super(qs);
            this.selectedColor = ko.observable();
        }

        public changeColorCallback = () => {
            this.selected(true);
        };

        public serialize = () => {
            return { color_key: this.selectedColor() || null };
        };

    }),

    set_user_color_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public selectedColor: Observable<string>;

        constructor() {
            super(qs);
            this.selectedColor = ko.observable();
        }

        public changeColorCallback = () => {
            this.selected(true);
        };

        public serialize = () => {
            return { user_color_key: this.selectedColor() || null };
        };

    }),

    manage_licenses_action: (qs) => new class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);
            this.enabled = ko.pureComputed(() => !!qs.commonSpeciesId());
            this.enabled.subscribe((v) => {
                if (!v) {
                    this.selected(false);
                }
            });
        }

    },

    set_license_action: (qs) => new class extends Action {

        public strainIds: PureComputed<number[]>;
        public licenseId: Observable<number>;
        public classificationId: Observable<number>;

        public availableLicenses: FetchBackendExtended<ObservableArray<LicenseOption>>;
        public availableClassifications: FetchBackendExtended<ObservableArray<LicenseClassificationOption|LicenseClassificationOptionDelimiter>>;

        public licenseAssignDate: Observable<string>;
        public licenseOveruseSelection: Computed<LicenseClassificationOption>;
        public licenseOveruseSelectionConfirm: CheckExtended<Observable<boolean>>;

        private animalCount: Computed<number>;

        constructor() {
            super(qs);

            this.animalCount = ko.computed(() => {
                return qs.selectedContext()
                    .map((item) => item.alive_count)
                    .reduce((total, current) => { return total + current; }, 0);
            });

            this.strainIds = ko.pureComputed(() => {
                return _.chain(qs.selectedContext()).map((tankData) => {
                    return tankData.strain_id || 0;
                }).uniq().value();
            });

            this.licenseId = ko.observable();
            this.availableLicenses = ko.observableArray().extend({
                fetchBackend: () => {
                    if (qs.commonSpeciesId()) {
                        return LicensesService.getLicenseOptions({
                            speciesId: qs.commonSpeciesId(),
                            strainId: this.strainIds(),
                        });
                    }
                },
            });

            this.classificationId = ko.observable();
            this.availableClassifications = ko.observableArray().extend({
                fetchBackend: () => {
                    if (qs.commonSpeciesId() && this.licenseId()) {
                        return LicensesService.getLicenseClassificationOptions({
                            licenseId: this.licenseId(),
                            speciesId: qs.commonSpeciesId(),
                            strainId: this.strainIds(),
                        });
                    }
                },
            });

            this.licenseAssignDate = ko.observable();

            // return potential selection of overused license classification
            // (=> number of selected animals is bigger than related available slots)
            this.licenseOveruseSelection = ko.computed(() => {
                return this.availableClassifications()
                    .find((item) => {
                        const option = item as LicenseClassificationOption;
                        return option.id === this.classificationId()
                            && (option.animal_number - option.used - this.animalCount()) < 0;
                    }) as LicenseClassificationOption;
            });

            this.licenseOveruseSelectionConfirm = ko.observable(false)
                .extend({
                    invalid: (value) => {
                        if (value === false
                            && this.selected()
                            && this.licenseOveruseSelection()
                        ) {
                            const availableCount = this.licenseOveruseSelection().animal_number - this.licenseOveruseSelection().used;

                            if (availableCount < 0) {
                                return getTranslation("You try to assign %s license classifications but it's already overused by %s")
                                    .replace("%s", String(this.animalCount()))
                                    .replace("%s", String(Math.abs(availableCount)));
                            } else {
                                return getTranslation("You try to assign %s license classifications but there are just %s left")
                                    .replace("%s", String(this.animalCount()))
                                    .replace("%s", String(availableCount));
                            }
                        }

                        return false;
                    },
                });

            this.enabled = ko.pureComputed(() => {

                // noinspection RedundantIfStatementJS
                if (!qs.commonSpeciesId()) {
                    return false;
                }

                return true;
            });
            this.enabled.subscribe((v) => {
                if (!v) {
                    this.selected(false);
                }
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                if (this.selected()) {
                    if (!this.licenseId()) {
                        this.errors.push(getTranslation("Please select a license"));
                        return false;
                    }

                    if (!this.classificationId()) {
                        this.errors.push(getTranslation("Please select a classification"));
                        return false;
                    }

                    if (this.licenseAssignDate() && isInvalidCalendarDate(this.licenseAssignDate())) {
                        this.errors.push(getTranslation("Invalid date"));
                        return false;
                    }

                    if (this.licenseOveruseSelectionConfirm.errorMessage()) {
                        this.errors.push(this.licenseOveruseSelectionConfirm.errorMessage());
                    }
                }

                return !this.licenseOveruseSelectionConfirm.isInvalid();
            });
        }

        public serialize = () => {
            return {
                classification_id: this.classificationId(),
                assign_date: this.licenseAssignDate(),
                confirmed_overuse: this.licenseOveruseSelectionConfirm(),
            };
        };

    },

    assign_crossing_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public crossingId: Observable<number>;
        public strainId: Observable<number>;
        public strainType: Observable<AssignCrossingStrainType>;
        public possibleStrainTypes: AssignCrossingStrainType[];
        public customStrain: Observable<string>;

        public licenseId: Observable<number>;
        public classificationId: CheckExtended<Observable<number>>;
        public availableLicenses: FetchBackendExtended<ObservableArray<LicenseOption>>;
        public availableClassifications: FetchBackendExtended<ObservableArray<LicenseClassificationOption|LicenseClassificationOptionDelimiter>>;

        public contextCounts: PureComputed<{
            tank: SelectableContext;
            countMale: CheckExtended<Observable<number>>;
            countFemale: CheckExtended<Observable<number>>;
            countUnknown: CheckExtended<Observable<number>>;
            totalInTank: number;
            totalSelected: CheckExtended<PureComputed<number>>;
        }[]>;
        public selectedContextCounts: PureComputed<{
            tank: SelectableContext;
            countMale: CheckExtended<Observable<number>>;
            countFemale: CheckExtended<Observable<number>>;
            countUnknown: CheckExtended<Observable<number>>;
            totalInTank: number;
            totalSelected: CheckExtended<PureComputed<number>>;
        }[]>;

        constructor() {
            super(qs);

            this.crossingId = ko.observable();
            this.strainId = ko.observable();
            const knownStrainType: AssignCrossingStrainType = {
                id: "known",
                label: getTranslation("Known Line / Strain"),
            };
            const newStrainType: AssignCrossingStrainType = {
                id: "new",
                label: getTranslation("New Line / Strain"),
            };
            this.possibleStrainTypes = [knownStrainType, newStrainType];
            this.strainType = ko.observable(knownStrainType);
            for (const combinedStrain of seed.separated_parent_strains) {
                this.possibleStrainTypes.push({
                    id: "parent",
                    value: combinedStrain,
                    label: combinedStrain,
                });
            }
            this.customStrain = ko.observable("")
                .extend({ invalid: v => !(v.length) });

            this.strainType.subscribe((strainType) => {
                if (strainType.id === "parent") {
                    // we try to pick existing strains first
                    for (const availableStrain of seed.available_strains) {
                        if (availableStrain.name === strainType.value) {
                            this.strainType(knownStrainType);
                            this.strainId(availableStrain.id);
                            return;
                        }
                    }
                    // if we don't find an existing strain, we create a new one
                    this.strainType(newStrainType);
                    this.customStrain(strainType.value);
                }
            });

            this.licenseId = ko.observable();
            this.availableLicenses = ko.observableArray().extend({
                fetchBackend: () => {
                    if (qs.commonSpeciesId()) {
                        return LicensesService.getLicenseOptions({
                            speciesId: qs.commonSpeciesId(),
                            strainId: [this.strainId()],
                        });
                    }
                },
            });
            this.classificationId = ko.observable().extend({
                invalid: value => {
                    if (!this.crossingId() && this.licenseId() && !value) {
                        return getTranslation("Please select a classification");
                    }

                    return false;
                },
            });
            this.availableClassifications = ko.observableArray().extend({
                fetchBackend: () => {
                    if (this.licenseId()) {
                        return LicensesService.getLicenseClassificationOptions({
                            licenseId: this.licenseId(),
                            speciesId: qs.commonSpeciesId(),
                            strainId: [this.strainId() || 0],
                        });
                    }
                },
            });

            this.contextCounts = ko.pureComputed(() => {
                return _.map(qs.context(), (t) => {

                    const totalInTank = t.number_of_male + t.number_of_female + t.number_of_unknown;
                    const countMale = ko.observable().extend({
                        invalid: (v) => {
                            return !(((_.isNumber(v) && String(v).match(/^\d+$/) && v <= totalInTank)
                                || _.isUndefined(v)) && (v || 0) >= 0);
                        },
                    });
                    const countFemale = ko.observable().extend({
                        invalid: (v) => {
                            return !(((_.isNumber(v) && String(v).match(/^\d+$/) && v <= totalInTank)
                                || _.isUndefined(v)) && (v || 0) >= 0);
                        },
                    });
                    const countUnknown = ko.observable().extend({
                        invalid: (v) => {
                            return !(((_.isNumber(v) && String(v).match(/^\d+$/) && v <= totalInTank)
                                || _.isUndefined(v)) && (v || 0) >= 0);
                        },
                    });

                    return {
                        tank: t,
                        countMale: countMale,
                        countFemale: countFemale,
                        countUnknown: countUnknown,
                        totalInTank: totalInTank,
                        totalSelected: ko.pureComputed(() => {
                            return (parseInt(countMale(), 10) || 0)
                                + (parseInt(countFemale(), 10) || 0)
                                + (parseInt(countUnknown(), 10) || 0);
                        }).extend({
                            invalid: (v) => {
                                return !(v <= totalInTank);
                            },
                        }),
                    };
                });
            });

            this.selectedContextCounts = ko.pureComputed(() => {
                return _.filter(this.contextCounts(), (t) => {
                    return t.tank.selected();
                });
            });

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected()) {
                    if (this.classificationId.isInvalid()) {
                        if (this.classificationId.errorMessage()) {
                            this.errors.push(this.classificationId.errorMessage());
                        }

                        return false;
                    }
                }

                if (_.reduce(this.selectedContextCounts(), (m, t) => {
                    return m + t.totalSelected();
                }, 0) < 1) {
                    return false;
                }

                return _.every(this.selectedContextCounts(), (t) => {
                    return t.countMale.isValid()
                        && t.countFemale.isValid()
                        && t.countUnknown.isValid()
                        && t.totalSelected.isValid();
                });

            });

            /**
             * Pre-fill the number of animals for tanks in crossing action.
             *
             * => If two tanks are selected with each having one animal, initially set corresponding number values
             *    (e.g.: Tank 1 has 1? and tank 2 has 1M, numbers in the crossing QS action will be pre-filled with
             *           "1? 0M 0F" for one tank and "0? 1M 0F" for the other tank).
             */
            ko.computed(() => {
                const tankSelection = this.contextCounts();

                if (tankSelection.length === 2 && tankSelection.every((t) => {
                    return t.totalInTank === 1;
                })) {
                    tankSelection.forEach((item) => {
                        item.countMale(item.tank.number_of_male);
                        item.countFemale(item.tank.number_of_female);
                        item.countUnknown(item.tank.number_of_unknown);
                    });
                }
            });
        }

        public serialize = () => {
            const data = {
                crossing_id: this.crossingId(),
                classification_id: this.classificationId(),
                counts: this.selectedContextCounts().map((t) => {
                    return {
                        number_of_male: (t.countMale() || 0),
                        number_of_female: (t.countFemale() || 0),
                        number_of_unknown: (t.countUnknown() || 0),
                    };
                }),
            };

            const strainType = this.strainType();
            if (strainType.id === "new") {
                return { strain_name: this.customStrain(), ...data };
            }
            return { strain_id: this.strainId(), ...data };
        };

    }),


    add_to_selection_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public extendTankSelection: Observable<boolean>;
        public contextCounts: PureComputed<{
            tank: SelectableContext;
            countMale: CheckExtended<Observable<number>>;
            countFemale: CheckExtended<Observable<number>>;
            countUnknown: CheckExtended<Observable<number>>;
            placeholder: PureComputed<string>;
        }[]>;
        public selectedContextCounts: PureComputed<{
            tank: SelectableContext;
            countMale: CheckExtended<Observable<number>>;
            countFemale: CheckExtended<Observable<number>>;
            countUnknown: CheckExtended<Observable<number>>;
            placeholder: PureComputed<string>;
        }[]>;

        constructor() {
            super(qs);
            this.extendTankSelection = ko.observable(false);

            this.contextCounts = ko.pureComputed(() => {
                return _.map(qs.context(), (t) => {

                    const totalInTank = t.number_of_male + t.number_of_female + t.number_of_unknown;
                    const countMale = ko.observable().extend({
                        invalid: (v) => {
                            return !(((_.isNumber(v) && String(v).match(/^\d+$/) && v <= totalInTank && v > 0)
                                || _.isUndefined(v)));
                        },
                    });
                    const countFemale = ko.observable().extend({
                        invalid: (v) => {
                            return !(((_.isNumber(v) && String(v).match(/^\d+$/) && v <= totalInTank && v > 0)
                                || _.isUndefined(v)));
                        },
                    });
                    const countUnknown = ko.observable().extend({
                        invalid: (v) => {
                            return !(((_.isNumber(v) && String(v).match(/^\d+$/) && v <= totalInTank && v > 0)
                                || _.isUndefined(v)));
                        },
                    });

                    return {
                        tank: t,
                        countMale: countMale,
                        countFemale: countFemale,
                        countUnknown: countUnknown,
                        placeholder: ko.pureComputed(() => {
                            if (countMale() || countFemale() || countUnknown()) {
                                return "0";
                            }
                            return getTranslation("All");
                        }),
                    };
                });
            });

            this.selectedContextCounts = ko.pureComputed(() => {
                return _.filter(this.contextCounts(), (t) => {
                    return t.tank.selected();
                });
            });

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (!session.pyratConf.MULTIPLE_WR && seed.have_work_requests) {
                    return false;
                }

                return _.every(this.selectedContextCounts(), (t) => {
                    return t.countMale.isValid()
                        && t.countFemale.isValid()
                        && t.countUnknown.isValid();
                });

            });

        }

        public serialize = () => {
            return {
                counts: _.map(this.selectedContextCounts(), (t) => {
                    return {
                        number_of_male: t.countMale(),
                        number_of_female: t.countFemale(),
                        number_of_unknown: t.countUnknown(),
                    };
                }),
                extend: this.extendTankSelection(),
            };
        };

    }),

    tank_export_to_scientist_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public ownerId: Observable<number>;

        constructor() {
            super(qs);

            this.ownerId = ko.observable();
            this.ownerId.subscribe(() => {
                this.selected(true);
            });


            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() && !this.ownerId()) {
                    this.errors.push(getTranslation("Please select an owner."));
                    return false;
                }

                return true;
            });
        }

        public serialize = () => {
            return { owner_id: this.ownerId() };
        };

    }),

    tank_export_to_institution_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public institutionId: Observable<number>;

        constructor() {
            super(qs);
            this.institutionId = ko.observable();
            this.institutionId.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() && !this.institutionId()) {
                    this.errors.push(getTranslation("Please select a facility"));
                    return false;
                }

                return true;
            });

        }

        public serialize = () => {
            return { institution_id: this.institutionId() };
        };

    }),

    close_tank_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public sacrificeMethodId: CheckExtended<Observable<number>>;
        public sacrificeReasonId: CheckExtended<Observable<number>>;
        public comment: Observable<string>;

        constructor() {
            super(qs);

            this.sacrificeMethodId = ko.observable(seed?.workrequest_details?.sacrifice_method_id).extend({
                invalid: (v) => {
                    return !(session.pyratConf.MANDATORY_SACRIFICE_METHOD ? !_.isUndefined(v) : true);
                },
            });

            this.sacrificeReasonId = ko.observable(seed?.workrequest_details?.sacrifice_reason_id).extend({
                invalid: (v) => {
                    return !(session.pyratConf.MANDATORY_SACRIFICE_REASON ? !_.isUndefined(v) : true);
                },
            });

            this.comment = ko.observable();

            this.sacrificeReasonId.subscribe(() => {
                this.selected(true);
            });
            this.sacrificeMethodId.subscribe(() => {
                this.selected(true);
            });
            if (seed?.workrequest_details?.behavior_name == "close_tank") {
                this.selected(true);
            }

            this.valid = ko.computed(() => {
                return !(this.sacrificeMethodId.isInvalid() ||
                         this.sacrificeReasonId.isInvalid());

            });
        }

        public serialize = () => {
            return {
                sacrifice_reason_id: this.sacrificeReasonId(),
                sacrifice_method_id: this.sacrificeMethodId(),
                comment: this.comment(),
            };
        };

    }),

    print_tank_action: (qs, seed) => new class extends Action {

        public printer: Observable<string>;
        public seed = seed;

        constructor() {
            super(qs);

            this.printer = ko.observable(seed.default_printer || undefined);
            this.printer.subscribe(() => {
                this.selected(true);
            });

            this.selected.subscribe(() => {
                if (qs.actions().reprint_required_action) {
                    qs.actions().reprint_required_action.selected(true);
                    // @ts-expect-error: The Action interface does not provide this property, but for this action it exists.
                    qs.actions().reprint_required_action.reprintRequired(false);
                }
            });

        }

        public serialize = () => ({
            printer: this.printer(),
        });

    },

    export_tank_action: (qs) => new (class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);
        }

    }),

    tank_add_comment_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public comment: Observable<NewComment>;

        constructor() {
            super(qs);

            this.comment = ko.observable();
            this.comment.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected()
                    && !this.comment()?.comment?.length
                    && !this.comment()?.attributes?.length
                ) {
                    this.errors.push(getTranslation("The comment field is empty"));
                    return false;
                }

                return true;
            });

        }

        public serialize = () => {
            return this.comment();
        };

    }),

    tank_add_document_action: (qs) => new (class extends Action {

        public pendingAction: Observable<boolean>;
        public newDocumentIds: ObservableArray<number>;

        constructor() {
            super(qs);

            this.pendingAction = ko.observable(false);
            this.newDocumentIds = ko.observableArray();
            this.newDocumentIds.subscribe((newValue) => {
                this.selected(newValue.length > 0);
            });

            this.valid = ko.pureComputed(() => {
                return !this.pendingAction() && this.newDocumentIds().length > 0;
            });

        }

        public serialize = () => {
            return {
                document_ids: this.newDocumentIds(),
            };
        };

    }),

    reprint_required_action: (qs) => new (class extends Action {

        public reprintRequired: Observable<boolean>;

        constructor() {
            super(qs);
            this.reprintRequired = ko.observable(false);
        }

        public serialize = () => {
            return { reprint_required: this.reprintRequired() };
        };

    }),

    archive_action: (qs) => new (class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);

            this.enabled = ko.pureComputed(() => {
                let isEnabled = false;
                const selectedTanks = qs.selectedContext();
                const finalTankStates = ["closed", "exported", "joined"];

                // enable archive action checkbox
                // -> all selected tanks need to be in final state (closed, exported or joined)
                if (selectedTanks && selectedTanks.length) {
                    isEnabled = selectedTanks.every((tank) => {
                        return finalTankStates.includes(tank.status)
                            && tank.final_state_period >= session.pyratConf.ARCHIVE_FINAL_STATE_PERIOD_MIN;
                    });
                }

                // deselect checkbox if archive action is disabled
                if (!isEnabled) {
                    this.selected(false);
                }

                return isEnabled;
            });

        }

    }),

    restore_action: (qs) => new (class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);
        }

    }),

    perform_workrequest_action: (qs, seed) => new class extends Action {

        public workrequestId = seed.workrequest_handling.workrequest_id;
        public requireConclusion = false;

        public closeWorkrequest: Observable<boolean>;

        public serialize = () => ({
            workrequest_id: seed.workrequest_handling.workrequest_id,
            close_workrequest: seed.workrequest_handling.close_workrequest,
        });

        constructor() {
            super(qs);
            this.selected(!!seed.workrequest_handling.workrequest_id);
        }

    },


};


class TankQuickselectViewModel {

    public reloadRequired: Observable<boolean>;
    public seedInProgress: Observable<boolean>;
    public seed: Observable<SeedType>;
    public context: Observable<SelectableContext[]>;
    public selectedContext: PureComputed<SelectableContext[]>;
    public selectedContextCount: PureComputed<number>;
    public commonSpeciesId: PureComputed<number | undefined>;
    public conclusion: ObservableArray<{ text: string; click: () => void }>;
    // public actions: PureComputed<{ [key in keyof typeof actionModels]: ReturnType<typeof actionModels[key]> }>;
    public actions: Observable<{ [key in keyof typeof actionModels]?: ReturnType<typeof actionModels[key]> }>;
    public applyInProgress: Observable<boolean>;
    public errors: ObservableArray<string>;
    private readonly params: Params;
    private readonly dialog: HtmlDialog;
    private scenery: PureComputed<{ loading: boolean } |
        { conclusion: TankQuickselectViewModel } |
        { context: TankQuickselectViewModel; actions: TankQuickselectViewModel } |
        { error: boolean }>;

    constructor(dialog: HtmlDialog, params: Params) {
        this.params = params;
        this.dialog = dialog;
        this.reloadRequired = ko.observable(false);

        this.dialog.addOnClose(() => {
            if (typeof params.closeCallback === "function") {
                params.closeCallback();
            }
            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });

        // get initial data
        this.seedInProgress = ko.observable(true);
        this.seed = ko.observable();
        this.context = ko.observableArray();
        this.actions = ko.observable();
        TanksService.getQuickselectActionsSeed({
            requestBody: {
                actions: this.params.actions,
                context: { tank_id: this.params.tankIds },
                workrequest_id: this.params.workrequestId,
                close_workrequest: this.params.closeWorkrequest,
            },
        })
            .then((response) => {
                this.seed(response);

                this.context(
                    // @ts-expect-error: The type is not defined properly yet.
                    response.context?.map((row) => ({
                        ...row,
                        selected: ko.observable(row.selected),
                    })),
                );

                const actions: { [key in keyof typeof actionModels]?: ReturnType<typeof actionModels[key]> } = {};
                if (response?.content) {
                    Object.keys(response.content).forEach((actionName: keyof SeedType["content"]) => {
                        if (actionName in actionModels) {
                            // @ts-expect-error: The typing does not map the action name to its.
                            actions[actionName] = actionModels[actionName](this, response.content[actionName]);
                        }
                    });
                }
                this.actions(actions);

            })
            .catch((reason) => {
                if (typeof reason.body?.detail == "string") {
                    this.errors.push(reason.body?.detail);
                } else {
                    this.errors.push(getTranslation("General quickselect error."));
                    writeException(reason);
                }
            })
            .finally(() => {
                this.seedInProgress(false);
            });

        this.selectedContext = ko.pureComputed(() => {
            return this.context().filter((r) => {
                return r.selected();
            });
        });

        this.commonSpeciesId = ko.pureComputed(() => {
            const speciesIds = _.chain(this.selectedContext())
                .map((tankData) => {
                    return tankData.species_id;
                })
                .uniq()
                .value();

            if (speciesIds.length === 1) {
                return speciesIds[0];
            }
        });

        this.selectedContextCount = ko.pureComputed(() => {
            return _.reduce(
                this.selectedContext(),
                (m, r) => {
                    return m + r.alive_count;
                },
                0,
            );
        });

        this.selectedContext.subscribe((selectedContext) => {
            dialog.setTitle(
                _.template(getTranslation("<%- animals %> animals in <%- tanks %> tanks selected."))({
                    tanks: _.keys(_.groupBy(selectedContext, "tank_location_id")).length,
                    animals: this.selectedContextCount(),
                }),
            );
        });

        // result handling

        this.conclusion = ko.observableArray([]);
        this.errors = ko.observableArray([]);
        this.applyInProgress = ko.observable(false);

        // routing
        this.scenery = ko.pureComputed(() => {
            if (this.seedInProgress()) {
                return { loading: true };
            }

            if (this.conclusion().length) {
                return { conclusion: this };
            }

            if (this.seed()) {
                return { context: this, actions: this };
            }

            return { error: true };
        });
    }

    public toggleSelection = () => {
        this.context().forEach((row) => {
            row.selected(!row.selected());
        });
    };

    public close = () => {
        this.dialog.close();
    };

    public canApply = () => {

        if (this.applyInProgress()) {
            return false;
        } else if (this.selectedContext().length < 1) {
            return false;
        } else if (!_.some(_.invokeMap(this.actions(), "selected"))) {
            // check if any action is selected
            return false;
        }

        // check if all selected actions are valid
        return _.every(this.actions(), (val) => {
            return val.selected() && val.valid ? Boolean(val.valid()) : true;
        });

    };

    public applyQuickselect = () => {
        this.errors.removeAll();

        this.applyInProgress(true);
        this.reloadRequired(true);
        _.forEach(this.actions(), (action) => {
            action.errors.removeAll();
        });
        TanksService.executeQuickselectActions({
            requestBody: {
                context: { tank_id: _.map(this.selectedContext(), "tank_id") },
                content: _.chain(this.actions())
                    .pickBy((a) => {
                        return a.selected();
                    })
                    .mapValues((a) => {
                        return a.serialize();
                    })
                    .value(),
            },
        })
            .then((response) => {
                this.conclude(response);

                const autoClose = !_.some(response?.content, (action_data, action: keyof Response["content"]) => {
                    return this.actions()[action] && this.actions()[action].requireConclusion;
                });

                if (autoClose) {
                    // no action requires a conclusion, so we immediately close it
                    this.close();
                }
            })
            .catch((response) => {
                if (response.body?.detail?.content) {
                    _.forEach(response.body.detail.content, (messages, name: keyof Response["content"]) => {
                        if (this.actions()[name] && this.actions()[name].errors) {
                            _.forEach(messages, (m) => {
                                this.actions()[name].errors.push(m);
                            });
                        } else {
                            _.forEach(messages, (m) => {
                                this.errors.push(m);
                            });
                        }
                    });
                } else {
                    this.errors.push(getTranslation("General quickselect error."));
                    writeException(response);
                }
            })
            .finally(() => this.applyInProgress(false));
    };

    private conclude = (value: ExecuteResponseType) => {

        // clear old results
        this.conclusion.removeAll();

        const tankIds = value?.context?.tank_id;
        const crossingId = value?.content?.assign_crossing_action?.crossing_id;
        const printRequest = value?.content?.print_tank_action;
        const exportRequest = value?.content?.export_tank_action;
        const tankSelectedForRequest = value?.content?.add_to_selection_action;
        const manageLicensesRequest = value?.content?.manage_licenses_action;

        if (tankIds) {
            this.conclusion.push({
                text: _.template(getTranslation("Show tanks (<%- count %>)."))({ count: tankIds.length }),
                click: () => {
                    this.reloadRequired(false);
                    mainMenu.openAndResetListFilter("get_tank_list", { tank_id: tankIds });
                },
            });
        }

        if (crossingId) {
            this.conclusion.push({
                text: _.template(getTranslation("Show involved tanks for crossing reference <%- ref %>."))({
                    ref: crossingId,
                }),
                click: () => {
                    this.reloadRequired(false);
                    mainMenu.openAndResetListFilter("get_tank_list", { crossing_id: [crossingId] });
                },
            });
            this.conclusion.push({
                text: _.template(getTranslation("Show crossing reference <%- ref %> in crossing list."))({
                    ref: crossingId,
                }),
                click: () => {
                    this.reloadRequired(false);
                    mainMenu.open("get_tank_crossing_list", { crossing_id: crossingId });
                },
            });
        }

        if (printRequest) {
            this.conclusion.push({
                text: getTranslation("Open the label in a new window."),
                click: () => {
                    window.open(getUrl(cgiScript("papercard.py"), printRequest));
                },
            });
            window.open(getUrl(cgiScript("papercard.py"), printRequest));
        }

        if (tankSelectedForRequest) {
            this.conclusion.push({
                text: _.template(getTranslation("Open a new request with <%- numberOfTanks %> tanks."))({
                    numberOfTanks: _.size(tankSelectedForRequest),
                }),
                click: () => {
                    frames.detailPopup.open(
                        getUrl(cgiScript("new_request.py"), {}, { absoluteUrl: true }),
                    );
                    this.reloadRequired(false);
                    this.dialog.close();
                },
            });
        }

        if (manageLicensesRequest) {
            this.reloadRequired(false);
            showSetLicense({
                subjects: {
                    tank_ids: manageLicensesRequest.tank_id,
                },
                reloadCallback: frames.reloadListIframe,
            });
        }

        if (exportRequest) {
            this.reloadRequired(false);
            showColumnSelect({
                mode: "export",
                viewName: "tank_list",
                exportArgs: {
                    page_start: 0,
                    page_size: exportRequest.tank_id.length,
                    ...exportRequest,
                },
            });
        }

    };

}


// dialog starter
export const showTankQuickselect = htmlDialogStarter(TankQuickselectViewModel, template, {
    name: "TankQuickselect",
    width: 600,
    position: {
        inset: { top: 20, right: 20 },
    },
    closeOthers: true,
});
